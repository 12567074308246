
import Main from '../views/news';
import detail from "../views/news/components/news-detail";

const news = [
  {
    path:'/news',
    component: Main,
  },
  {
    path:'/news/detail/:id',
    component:detail
  }
];

export default news;