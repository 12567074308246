<template>
  <div class="wrapper">
    <!-- banner -->
    <div class="banner">
      <img src="@/assets/images/terrace/banner3.jpg" alt="" />
    </div>
    <!------  温湿度自动监控平台先驱者 ------>
    <div class="main">
      <div class="heat">
        <div class="heat-title">验证服务介绍</div>
        <p>
          北京中科希望物联网络科技有限公司，是一家具有互联网基因的资深医药行业监测、验证服务提供商，2011年成立发展至今，一直致力于提供基于云平台的、真实、实时的验证服务；从GSP制定目的入手，结合客户实际需求，紧跟GSP版本更新、新国标出台和各地监管部门要求和各类客户新的需求，完成并不断完善我们使用的验证设备、验证规范和验证工具；中科物联验证服务提供真正的实时验证、实时纠偏，验证方案来源于实际需求，验证结论可以用于实际操作，定制模板+APP控制，验证完成后只要简单的操作就可以出具定制验证报告。
        </p>
        <div class="heat-title">验证服务优势：</div>
        <div class="heat-main">
          <!-- <ol>
            <li>自主研发专用验证平台、设备</li>
            <li>专业的验证实施人员</li>
            <li>现场即时纠偏处理</li>
            <li>丰富的大型验证项目实施经验</li>
            <li>完善的服务保证</li>
          </ol> -->
          <div>
            <img src="@/assets/images/terrace/pic2.jpg" alt="">
          </div>
          <div>
             <img src="@/assets/images/terrace/pic3.jpg" alt="">
          </div>
          <div>
             <img src="@/assets/images/terrace/pic4.jpg" alt="">
          </div>
          <div>
             <img src="@/assets/images/terrace/pic5.jpg" alt="">
          </div>
          <div>
             <img src="@/assets/images/terrace/pic6.jpg" alt="">
          </div>
        </div>
      </div>
      <!-- end -->
      <div class="function">
        <div class="funTitle">验证服务项目:</div>
        <div class="function_main">
          <div>冷库</div>
          <div>冷藏车</div>
          <div>保温箱</div>
          <div>冷藏箱</div>
        </div>
        <div class="function_main">
          <div>冷柜</div>
          <div>常温库</div>
          <div>超低温验证</div>
          <div>系统验证</div>
        </div>
      </div>
      <!------  全面解决方案 ------>

      <div class="heat">
        <div class="heat-title">验证流程：</div>
        <img
          src="@/assets/images/mpItem/mpItem_6.jpg"
          alt=""
        />
      </div>
      <div class="heat">
        <div class="heat-title">验证服务实施：</div>
        <img
          src="@/assets/images/terrace/pic7.jpg"
          alt=""
        />
      </div>
      <div class="heat">
        <div class="heat-title">验证服务管控：</div>
        <img
          src="@/assets/images/mpItem/mpItem_8.jpg"
          alt=""
        />
      </div>
      <div class="heat">
        <div class="heat-title">验证服务系统架构：</div>
        <img
          src="@/assets/images/mpItem/mpItem_7.jpg"
          alt=""
        />
      </div>
      <!------  荣誉资质 ------>

      <div class="tab-content">
        <div class="item" ref="tabItem1">
          <div class="title">{{ honorData.title }}</div>
          <!-- <p>{{ honorData.pOne }}</p>
          <p>{{ honorData.pTwo }}</p> -->
          <p v-for="item,i in honorData.pTextList" :key="i">{{item}}</p>
          <img 
            v-for="item,index in honorData.honorImgList" 
            :key="index" 
            :src="item.imgSrc" 
            alt="" 
            style="object-fit: contain; width: 100%;" 
            :class="index===8||index===9?'honorImg':''"
            loading="lazy"
          />
        </div>
        <!-- <div class="item">
          <div class="title">{{ zizhi.title }}</div>
          <p>{{ zizhi.title_p1 }}</p>
          <p>{{ zizhi.title_p2 }}</p>
          <p>{{ zizhi.title_p3 }}</p>
          <div v-for="item,index in honorData.honorImgList.slice(0,6)" 
            :key="index" >
            <img
            :src="item.imgSrc" 
            alt="" 
            style="width:100%;height:100%" 
          />
          </div>
        </div>
        <div class="item">
          <div class="title">{{ qiye.title }}</div>
          <p>{{ qiye.title_p1 }}</p>
          <div class="qiyeImg">
            <img :src="qiye.title_imgSrc1" alt="" />
            <img :src="qiye.title_imgSrc2" alt="" />
          </div>
        </div> -->
        <div class="item">
          <div class="title">{{ kehu.title }}</div>
          <img class="kehuImg" :src="kehu.title_imgSrc" alt="" loading="lazy" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { zizhi, qiye, kehu } from '@/utils/config/about'

import { heatData, schemeData, honorData } from '@/utils/config/home'

export default {
  data() {
    return {
      heatData,
      schemeData,
      honorData,

      zizhi,
      qiye,
      kehu,

    }
  },
}
</script>

<style lang="less" scoped>
.honorImg{
  margin: 0 53px;
  width: 40% !important;
}
.wrapper {
  background-color: #f3f7fa;
  .banner {
    img {
      width: 100vw;
      height: calc(width / 6.4);
    }
  }
  .main {
    width: 1200px;
    margin: 0 auto;
    .heat {
      min-width: 1200px;
      margin: 0 auto;
      background-color: #f3f7fa;
      text-align: left;
      // text-indent: 2em;
      font-size: 16px;
      line-height: 26px;
      .heat-title {
        margin: 30px auto;
        color: #333e4c;
        font-size: 36px;
        font-weight: bold;
        text-align: left;
      }
      p {
        text-indent: 2em;
      }
      .heat-text {
        width: 260px;
        margin: 10px auto 0px;
        display: flex;
        justify-content: space-between;
        font-size: 26px;
      }
      .heat-main {
        width: 1200px;
        margin: 30px auto;
        display: flex;
        justify-content: space-between;
        // img {
        //   object-fit: contain;
        //   cursor: pointer;
        // }
        ol {
          padding-inline-start: 0px;
          li {
            color: #666;
            line-height: 46px;
            font-size: 23px;
          }
        }
      }
      .heat-footBtn {
        width: 100px;
        padding: 5px 15px;
        margin: 0 auto;
        display: flex;
        border: 1px solid #000;
        text-align: center;
      }
    }

    .function {
      width: 100%;
      .funTitle {
        margin-top: 30px;
        color: #333e4c;
        font-size: 36px;
        font-weight: bold;
        text-align: left;
      }
      .function_main {
        display: flex;
        justify-content: space-around;
        div {
          width: 136px;
          margin-top: 30px;
          padding: 10px;
          font-size: 26px;
          font-weight: bold;
          border: 1px solid #333e4c;
        }
      }
    }

    .scheme {
      min-width: 1200px;
      margin-top: 30px;
      text-align: left;
      .scheme-title {
        color: #333e4c;
        font-size: 36px;
        font-weight: bold;
      }
      .scheme-text {
        margin-top: 20px;
        font-size: 26px;
        font-weight: 600;
      }
      .scheme-main {
        width: 1200px;
        margin-top: 30px;
        display: flex;
        flex-flow: wrap;
        justify-content: space-around;
        .scheme-main_item {
          font-size: 0;
          margin-bottom: 30px;
          cursor: pointer;
          .scheme-main_itemName {
            color: #fff;
            font-size: 24px;
            font-weight: bold;
            background-color: #333e4c;
            text-align: center;
          }
        }
      }
    }
    .tab-content {
      width: 1200px;
      margin: 0 auto;
      padding-bottom: 60px;
      .item:first-child {
        margin-top: 0px;
      }
      .item {
        margin-top: 30px;
        text-align: left;
        .title {
          font-size: 36px;
          font-weight: bold;
          color: #333e4c;
        }
        p {
          color: #666;
          font-size: 16px;
          line-height: 30px;
          text-indent: 2em;
        }
        .title_h2 {
          margin-top: 30px;
          color: #2eb1c1;
          font-size: 20px;
        }
        ol {
          padding-inline-start: 16px;
          li {
            list-style: disc;
            padding-inline-start: 0px;
            color: #666;
            font-size: 16px;
            line-height: 30px;
          }
        }
        .qiyeImg {
          display: flex;
          justify-content: space-around;
        }
        .kehuImg {
          margin-top: 30px;
          width: 100%;
        }
      }
    }
  }

  .text_cut {
    width: 2px;
    height: 23px;
    margin-top: 7px;
    border-left: 2px solid #333e4c;
  }
}
</style>