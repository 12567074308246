var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_vm._m(0),_c('div',{staticClass:"main"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"tab-content"},[_c('div',{ref:"tabItem1",staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.honorData.title))]),_vm._l((_vm.honorData.pTextList),function(item,i){return _c('p',{key:i},[_vm._v(_vm._s(item))])}),_vm._l((_vm.honorData.honorImgList),function(item,index){return _c('img',{key:index,class:index===8||index===9?'honorImg':'',staticStyle:{"object-fit":"contain","width":"100%"},attrs:{"src":item.imgSrc,"alt":"","loading":"lazy"}})})],2),_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.kehu.title))]),_c('img',{staticClass:"kehuImg",attrs:{"src":_vm.kehu.title_imgSrc,"alt":"","loading":"lazy"}})])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":require("@/assets/images/terrace/banner3.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"heat"},[_c('div',{staticClass:"heat-title"},[_vm._v("验证服务介绍")]),_c('p',[_vm._v(" 北京中科希望物联网络科技有限公司，是一家具有互联网基因的资深医药行业监测、验证服务提供商，2011年成立发展至今，一直致力于提供基于云平台的、真实、实时的验证服务；从GSP制定目的入手，结合客户实际需求，紧跟GSP版本更新、新国标出台和各地监管部门要求和各类客户新的需求，完成并不断完善我们使用的验证设备、验证规范和验证工具；中科物联验证服务提供真正的实时验证、实时纠偏，验证方案来源于实际需求，验证结论可以用于实际操作，定制模板+APP控制，验证完成后只要简单的操作就可以出具定制验证报告。 ")]),_c('div',{staticClass:"heat-title"},[_vm._v("验证服务优势：")]),_c('div',{staticClass:"heat-main"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/images/terrace/pic2.jpg"),"alt":""}})]),_c('div',[_c('img',{attrs:{"src":require("@/assets/images/terrace/pic3.jpg"),"alt":""}})]),_c('div',[_c('img',{attrs:{"src":require("@/assets/images/terrace/pic4.jpg"),"alt":""}})]),_c('div',[_c('img',{attrs:{"src":require("@/assets/images/terrace/pic5.jpg"),"alt":""}})]),_c('div',[_c('img',{attrs:{"src":require("@/assets/images/terrace/pic6.jpg"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"function"},[_c('div',{staticClass:"funTitle"},[_vm._v("验证服务项目:")]),_c('div',{staticClass:"function_main"},[_c('div',[_vm._v("冷库")]),_c('div',[_vm._v("冷藏车")]),_c('div',[_vm._v("保温箱")]),_c('div',[_vm._v("冷藏箱")])]),_c('div',{staticClass:"function_main"},[_c('div',[_vm._v("冷柜")]),_c('div',[_vm._v("常温库")]),_c('div',[_vm._v("超低温验证")]),_c('div',[_vm._v("系统验证")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"heat"},[_c('div',{staticClass:"heat-title"},[_vm._v("验证流程：")]),_c('img',{attrs:{"src":require("@/assets/images/mpItem/mpItem_6.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"heat"},[_c('div',{staticClass:"heat-title"},[_vm._v("验证服务实施：")]),_c('img',{attrs:{"src":require("@/assets/images/terrace/pic7.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"heat"},[_c('div',{staticClass:"heat-title"},[_vm._v("验证服务管控：")]),_c('img',{attrs:{"src":require("@/assets/images/mpItem/mpItem_8.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"heat"},[_c('div',{staticClass:"heat-title"},[_vm._v("验证服务系统架构：")]),_c('img',{attrs:{"src":require("@/assets/images/mpItem/mpItem_7.jpg"),"alt":""}})])
}]

export { render, staticRenderFns }