<template>
  <div class="wrapper">
    <!-- banner轮播部分 -->

    <HomeSwiper />

    <!------  温湿度自动监控平台先驱者 ------>

    <div class="heat">
      <h1 class="heat-title">{{ heatData.title }}</h1>
      <div class="heat-text">
        <div>{{ heatData.text1 }}</div>
        <div class="text_cut"></div>
        <div>{{ heatData.text2 }}</div>
        <div class="text_cut"></div>
        <div>{{ heatData.text3 }}</div>
      </div>
      <div class="heat-main">
        <img
          v-for="item in heatData.list"
          :key="item.id"
          :src="item.offImg"
          @mouseenter="enterDevice(item)"
          @mouseleave="leaveDevice(item)"
          @click="modelFunction(item.id)"
        />
      </div>
      <Model v-if="isShowModel" :modelId="modelId" @showModel="showModel" />
    </div>

    <div class="deviceBootom"></div>
    <!-- end -->

    <!------  大型医药物流集团的选择 ------>

    <div class="select">
      <div class="select-title">{{ selectData.title }}</div>
      <div class="heat-title2">{{ selectData.title2 }}</div>
      <div class="select-text">
        <div>{{ selectData.text1 }}</div>
        <div class="text_cut"></div>
        <div>{{ selectData.text2 }}</div>
        <div class="text_cut"></div>
        <div>{{ selectData.text3 }}</div>
      </div>
      <div class="select-main">
        <div
          class="select-main_item"
          v-for="(item, index) in selectData.list"
          :key="item.id"
          @click="toVerify(item, index)"
        >
          <img :src="item.imgSrc" alt="" />
          <div>
            <div class="select-main_itemName">{{ item.name }}</div>
            <div class="select-main_itemCut"></div>
            <div class="select-main_itemDescribe">
              {{ item.describe }}
            </div>
            <div class="itemBtn">
              <img
                :src="item.offImg"
                @mouseenter="enterDevice(item)"
                @mouseleave="leaveDevice(item)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!------  全系自主研发智能监测设备 ------>

    <div class="device">
      <div class="device-title">全系自主研发智能监测设备</div>
      <div class="device-main">
        <img
          v-for="(item, index) in deviceImg"
          :key="item.id"
          :src="item.offImg"
          @mouseenter="enterDevice(item)"
          @mouseleave="leaveDevice(item)"
          @click="emit(item, index)"
        />
      </div>
    </div>
    <div class="deviceBootom"></div>

    <!------  全面解决方案 ------>

    <div class="scheme">
      <div class="scheme-title">{{ schemeData.title }}</div>
      <div class="scheme-text">{{ schemeData.text }}</div>
      <div class="scheme-main">
        <div
          class="scheme-main_item"
          v-for="item in schemeData.list"
          :key="item.id"
          @mouseenter="enterDevice(item)"
          @mouseleave="leaveDevice(item)"
          @click="toPlan(item)"
        >
          <img :src="item.offImg" alt="" />
          <div class="scheme-main_itemName">{{ item.name }}</div>
        </div>
      </div>
    </div>

    <!------  覆盖全国的服务范围 ------>

    <div class="range">
      <div class="range-title">{{ rangeData.title }}</div>
      <div class="range-map">
        <img :src="rangeData.mapImgSrc" alt="" />
        <div class="range-map_right">
          <img :src="rangeData.mpaIconSrc" alt="" />
          <div class="right-t">{{ rangeData.text }}</div>
          <div class="right-b">{{ rangeData.name }}</div>
          <ol>
            <li v-for="(item, index) in rangeData.list" :key="index">
              {{ item }}
            </li>
          </ol>
          <div class="range-map_rightBtn" @click="tosell()">
            {{ rangeData.btn }}
          </div>
        </div>
      </div>
    </div>

    <!------  荣誉资质 ------>

    <div class="honor">
      <div class="honor-title">{{ honorData.title }}</div>
      <div class="honor-p">
        <!-- <p class="honor-p_1">{{ honorData.pOne }}</p>
        <p class="honor-p_2">{{ honorData.pTwo }}</p> -->
        <p v-for="item,i in honorData.pTextList" :key="i">{{item}}</p>
      </div>
      <img 
        v-for="item,index in honorData.honorImgList" 
        :key="index" 
        :src="item.imgSrc" 
        alt="" 
        style="object-fit: contain;" 
        :class="index===8||index===9?'honorImg':''"
      />
    </div>
  </div>
</template>

<script>
import HomeSwiper from './components/homeSwiper'
import Model from '@/components/terrace-model.vue'

import {
  heatData,
  selectData,
  deviceImg,
  schemeData,
  rangeData,
  honorData
} from '@/utils/config/home'

export default {
  name:'HomeIndex',
  components: {
    HomeSwiper,
    Model
  },
  data() {
    return {
      heatData,
      selectData,
      deviceImg,
      schemeData,
      rangeData,
      honorData,
      isShowModel: false,
      modelId: 0
    }
  },
  mounted() {},
  methods: {
    toVerify(item, index) {
      let routeData = {}
      if (index===0) {
        routeData = this.$router.resolve({ 
            path: '/verifyPlan'
          })
      }
      if(index===1){
        routeData = this.$router.resolve({
            path: '/terraceIntro2'
          })
      }
      if (index===2||index===3) {
        routeData = this.$router.resolve({
            path: '/verifyDevice'
          })
      }
      window.open(routeData.href, '_blank')
    },
    toPlan(item) {
      let routeData = this.$router.resolve({
        path: item.routeUrl
      })
      window.open(routeData.href, '_blank')
    },
    tosell() {
      let routeData = this.$router.resolve({
        path: '/sell'
      })
      window.open(routeData.href, '_blank')
    },
    emit(item, index) {
      this.$store.commit('UPDATE_Index', index)
      let routeData = this.$router.resolve({
        path: '/monitorDevice',
        query: {
          curIndex: index
        }
      })
      window.open(routeData.href, '_blank')
      this.leaveDevice(item)
    },
    enterDevice(item) {
      item.toggle = item.offImg
      item.offImg = item.onImg
    },
    leaveDevice(item) {
      item.offImg = item.toggle
    },
    modelFunction(id) {
      this.isShowModel = true
      this.modelId = id
    },
    showModel(isShowModel) {
      this.isShowModel = isShowModel
    }
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  .heat {
    min-width: 1200px;
    margin: 0 auto;
    padding: 30px 0px;
    background-color: #f3f7fa;
    .heat-title {
      color: #333e4c;
      font-size: 48px;
      font-weight: bold;
    }
    .heat-text {
      width: 260px;
      margin: 10px auto 0px;
      display: flex;
      justify-content: space-between;
      font-size: 26px;
    }
    .heat-main {
      width: 1000px;
      margin: 60px auto;
      display: flex;
      justify-content: space-between;
      img {
        object-fit: contain;
        cursor: pointer;
      }
    }
    .heat-footBtn {
      width: 100px;
      padding: 5px 15px;
      margin: 0 auto;
      display: flex;
      border: 1px solid #000;
      text-align: center;
    }
  }
  .select {
    min-width: 1200px;
    margin: 0 auto;
    padding: 60px 0px;
    .select-title {
      font-size: 48px;
      font-weight: 800;
    }
    .heat-title2 {
      color: #333e4c;
      font-size: 36px;
      font-weight: bold;
    }
    .select-text {
      width: 460px;
      margin: 20px auto 0px;
      display: flex;
      justify-content: space-between;
      font-size: 26px;
    }
    .select-text_cut {
      width: 1px;
      height: 13px;
      border-left: 1px solid #333e4c;
    }
    .select-main {
      width: 1200px;
      margin: 60px auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .select-main_item {
        width: 400px;
        margin: 20px auto;
        padding-right: 30px;
        display: flex;
        img {
          object-fit: contain;
        }
        div {
          margin-left: 10px;
          position: relative;
          .select-main_itemName {
            color: #00bba8;
            font-size: 30px;
            font-weight: bold;
            text-align: left;
          }
          .select-main_itemCut {
            width: 60px;
            height: 3px;
            border-bottom: 3px solid #00bba8;
          }
          .select-main_itemDescribe {
            width: 340px;
            font-size: 18px;
            text-align: left;
          }
          .itemBtn {
            position: absolute;
            bottom: -30px;
            right: 0;
            cursor: pointer;
          }
        }
      }
    }
  }
  .device {
    min-width: 1200px;
    margin: 0 auto;
    background: url(../../assets/images/home/tab3_bg.jpg) no-repeat;
    background-size: 100% 100%;
    .device-title {
      padding: 60px;
      color: #00bba8;
      font-size: 48px;
      font-weight: 800;
    }
    .device-main {
      width: 1200px;
      margin: 0 auto;
      padding-bottom: 60px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      img {
        object-fit: contain;
      }
    }
  }
  .scheme {
    min-width: 1200px;
    margin: 0 auto;
    padding: 60px 0px;
    .scheme-title {
      color: #333e4c;
      font-size: 48px;
      font-weight: bold;
    }
    .scheme-text {
      margin-top: 20px;
      font-size: 26px;
      font-weight: 600;
    }
    .scheme-main {
      width: 1200px;
      margin: 60px auto 0px;
      display: flex;
      flex-flow: wrap;
      justify-content: space-around;
      .scheme-main_item {
        font-size: 0;
        margin-bottom: 30px;
        cursor: pointer;
        .scheme-main_itemName {
          color: #fff;
          font-size: 24px;
          font-weight: bold;
          background-color: #333e4c;
          height: 50px;
          line-height: 50px;
        }
      }
    }
  }
  .range {
    min-width: 1200px;
    margin: 0 auto;
    padding: 60px 0px;
    background-color: #f3f7fa;
    .range-title {
      color: #333e4c;
      font-size: 48px;
      font-weight: bold;
    }
    .range-map {
      width: 1100px;
      margin: 60px auto 0px;
      display: flex;
      .range-map_right {
        .right-t {
          padding-left: 30px;
          font-size: 24px;
          font-weight: bold;
          color: #00bba8;
          text-align: left;
        }
        .right-b {
          padding: 20px 0px 0px 30px;
          font-size: 24px;
          font-weight: bold;
          text-align: left;
        }
        ol {
          padding-inline-start: 46px;
          li {
            margin-top: 6px;
            list-style: circle;
            font-size: 18px;
            text-align: left;
          }
        }
        .range-map_rightBtn {
          width: 160px;
          margin: 30px auto 0px;
          padding: 6px 0px;
          color: #00bba8;
          border: 2px solid #00bba8;
          border-radius: 6px;
          font-size: 18px;
          font-weight: bold;
        }
        .range-map_rightBtn:hover {
          color: #333e4c;
          background-color: #00bba8;
          cursor: pointer;
        }
      }
    }
  }
  .honor {
    min-width: 1200px;
    margin: 0 auto;
    padding: 60px 0px;
    .honor-title {
      font-size: 48px;
      font-weight: bold;
    }
    .honor-p {
      width: 930px;
      margin: 0 auto;
      p {
        font-size: 18px;
        line-height: 28px;
        text-align: left;
        text-indent: 2em;
      }
      .honor-p_1 {
        margin-top: 60px;
      }
      .honor-p_2 {
        margin: 30px 0px;
      }
    }
    .honorImg {
      margin: 0px 40px;
    }
  }
  .deviceBootom {
    height: 10px;
    background-color: #d5d5d5;
  }
  .text_cut {
    width: 2px;
    height: 23px;
    margin-top: 7px;
    border-left: 2px solid #333e4c;
  }
}
</style>