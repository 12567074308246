<template>
  <div class="wrapper">
    <div class="banner">
      <!-- <img src="@/assets/images/about/top_aboutme.jpg" alt="" /> -->
      <img ref="banImg" src="@/assets/images/home/jdt_3.jpg" alt="" />
    </div>
    <div class="tabNav">
      <div class="tabNav-wra" ref="tabTitle">
        <ul class="tabNav-tit">
        <li
          v-for="(item, index) in groupsList"
          :key="item.id"
          @click="toTabContent(index)"
          :class="cur === index ? 'tabActive' : 'hiddenTit'"
        >
          {{ item.name }}
        </li>
      </ul>
      </div>
      

      <ul class="hiddenTit" v-show="isTab"></ul>

      <div class="tab-content">
        <div class="item" ref="tabItem0">
          <div class="title">{{ compareData.title }}</div>
          <p>{{ compareData.title_p1 }}</p>
          <p>{{ compareData.title_p2 }}</p>
          <p>{{ compareData.title_p3 }}</p>
          <div class="title_h2">{{ compareData.title_h2 }}</div>
          <ol>
            <li v-for="item in compareData.title_h2_list" :key="item.id">
              {{ item.text }}
            </li>
          </ol>
          <div class="title_h2">{{ compareData.title_h3 }}</div>
          <ol>
            <li v-for="item in compareData.title_h3_list" :key="item.id">
              {{ item.text }}
            </li>
          </ol>
        </div>
        <div class="item" ref="tabItem1">
          <div class="title">{{ honorData.title }}</div>
          <!-- <p>{{ honorData.pOne }}</p>
          <p>{{ honorData.pTwo }}</p> -->
          <p v-for="item,i in honorData.pTextList" :key="i">{{item}}</p>
          <img 
            v-for="item,index in honorData.honorImgList" 
            :key="index" 
            :src="item.imgSrc" 
            alt="" 
            style="object-fit: contain; width: 100%;" 
            :class="index===8||index===9?'honorImg':''"
          />

        </div>
        <!-- <div class="item" ref="tabItem1">
          <div class="title">{{ zizhi.title }}</div>
          <p>{{ zizhi.title_p1 }}</p>
          <p>{{ zizhi.title_p2 }}</p>
          <p>{{ zizhi.title_p3 }}</p>
          <img :src="zizhi.title_imgSrc" alt="" />
        </div> -->
        <!-- <div class="item" ref="tabItem2">
          <div class="title">{{ qiye.title }}</div>
          <p>{{ qiye.title_p1 }}</p>
          <div class="qiyeImg">
            <img :src="qiye.title_imgSrc1" alt="" />
            <img :src="qiye.title_imgSrc2" alt="" />
          </div>
        </div> -->
        <div class="item" ref="tabItem2">
          <div class="title">{{ kehu.title }}</div>
          <img class="kehuImg" :src="kehu.title_imgSrc" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { groupsList,compareData, zizhi, qiye, kehu } from "@/utils/config/about";
import { honorData } from '@/utils/config/home'
import {groupsMinxins} from "@/mixins/groupsMinxins";
export default {
  name:'aboutIndex',
  mixins:[groupsMinxins],
  data() {
    return {
      cur: 0,
      isTab: false,
      groupsList,
      compareData,
      zizhi,
      qiye,
      kehu,
      honorData,
    };
  },
};
</script>

<style lang="less" scoped>
.honorImg{
  margin: 0 53px;
  width: 40% !important;
}
.wrapper {
  background-color: #f4f4f4;
  .banner {
    img{
      width: 100vw;
      // height: calc(width / 6.4);
    }
  }
  .tabNav {
    .tabNav-wra{
      width: 100%;
      height: 63px;
      background-color: #fff;
      .tabNav-tit {
      margin: 0 auto;
      padding-top: 19px;
      width: 1200px;
      height: 63px;
      cursor: pointer;
      li {
        padding: 0px 16px;
        text-align: center;
        font-size: 16px;
        line-height: 44px;
        display: block;
        float: left;
        margin-left: 11px;
        text-decoration: none;
        width: auto;
        height: 44px;
        border: 1px solid #e3e1e2;
        border-bottom: 0;
        color: #e3e1e2;
        background-color: #5c6570;
      }
      .tabActive {
        color: #333e4c;
        background-color: #f4f4f4;
        text-align: center;
        font-size: 16px;
        line-height: 44px;
        display: block;
        float: left;
        margin-left: 11px;
        text-decoration: none;
      }
    }
    }
    
    .hiddenTit {
      width: 1200px;
      height: 63px;
      margin: 0 auto;
      padding-top: 16px;
    }
  }
  .tab-content {
    width: 1076px;
    margin: 0 auto;
    padding-bottom: 60px;
    .item {
      margin-top: 60px;
      text-align: left;
      .title {
        font-size: 36px;
        font-weight: bold;
        color: #333e4c;
      }
      p {
        color: #666;
        font-size: 16px;
        line-height: 30px;
        text-indent: 2em;
      }
      .title_h2 {
        margin-top: 30px;
        color: #2eb1c1;
        font-size: 20px;
      }
      ol {
        padding-inline-start: 16px;
        li {
          list-style: disc;
          padding-inline-start: 0px;
          color: #666;
          font-size: 16px;
          line-height: 30px;
        }
      }
      .qiyeImg {
        display: flex;
        justify-content: space-around;
      }
      .kehuImg {
        margin-top: 30px;
        width: 100%;
      }
    }
  }
  .fixedStyle {
    display: block;
    position: fixed;
    z-index: 999;
    top: 0;
  }
}
</style>