export const groupsList= [
    { id: 1, name: "公司简介" },
    { id: 2, name: "荣誉资质" },
    // { id: 3, name: "企业荣誉" },
    { id: 4, name: "典型客户" },
  ]

export const compareData = {
    title:"公司简介",
    title_p1:"北京中科希望物联网络科技有限公司，自2011年成立，十三年始终秉承“为老百姓吃药保驾护航，为人民服务”的宗旨，专注于医药行业温湿度监测课题，致力于解决药品生产企业、医院、三方物流以及经营企业的温湿度监测难题。我们的业务覆盖全国，通过八个分公司（北京分公司、河北分公司、广州分公司、上海分公司、西安分公司、成都分公司、青岛分公司、苏州分公司）为客户提供技术支持及售后服务，为药品生产企业、医院、三方物流以及经营企业提供全方位的温湿度监测和验证服务。",
    title_p2:'北京中科希望物联网络科技有限公司，一支由云技术、物联网、数据安全和验证技术专家组成的团队，所有的硬件设备和软件及云平台都是我们自主研发的，全心打造符合新版GSP政策要求，降低企业技术投入，减轻运维难度的监测系统，并与云技术应用相结合，为客户提供完整的温湿度监测解决方案。协助企业通过新版GSP验证，建立符合新版GSP要求的温湿度监测系统。涵盖常温库、阴凉库、冷库、冷柜、冷藏车、保温箱等医药品储运的各个环节的全冷链的服务。公司自主研制了智能化监测设备和验证设备，拥有多项专利，为药品储运提供全面的监测系统。产品系列覆盖各类医药品储运设施，通过自主研发的技术，确保药品质量与安全，满足客户在合规、效率和安全性方面的需求，推动客户向集团化和平台化战略发展。',
    title_p3:'我们公司的使命是为老百姓吃药保驾护航，全心全意为人民服务，我们将继续致力于医药行业的温湿度监测和验证服务，为人民提供安全、可靠的药品保障。',
    title_h2:"业界独一无二的监控管理工具: 中科物联温湿度自动监测系统",
    title_h2_list:[
        {id:1,text:"完全符合国家新版GSP要求"},
        {id:2,text:"仓库、冷柜、冷藏车、保温箱四个系统通过一个平台统一管理"},
        {id:3,text:"全图型界面简单易用"},
        {id:4,text:"托管模式彻底免除企业维护困扰"},
        {id:5,text:"无主机方式消除软硬件升级、系统改造之忧"},
        {id:6,text:"便捷的查询方式：计算机、平板电脑、手机"},
        {id:7,text:"国家最高级别数据中心确保数据永不丢失"},
        {id:8,text:"监测数据自动采集、自动传输、自动记录、自动存储保障数据原始、真实、可靠。高度契合政策要求"},
        {id:9,text:"前瞻的系统设计预留“在线远程监管”功能"},
        {id:10,text:"全年不间断运行确保数据的连续性"},
        {id:11,text:"温湿度系统建设成本至少节约85%"},
        {id:12,text:"集中管理方式尤为适用于异地管理网点分散的企业"},
        {id:13,text:"结合随货通行单管理冷链运输全过程，提供全面详细的发货地点 、起运时间、抵达时间、全程温度数据……"},
        {id:14,text:"前瞻的系统设计预留“报警处理痕迹”功能"},
    ],
    title_h3:"全面周到的“管家式”验证服务",
    title_h3_list:[
        {id:1,text:"量身定制验证方案"},
        {id:2,text:"制定验证路线图"},
        {id:3,text:"相关岗位人员验证理论培训"},
        {id:4,text:"制定相关岗位验证操作规程"},
        {id:5,text:"为质管负责人专门制定进程监督表"},
        {id:6,text:"实时纠偏处理以及纠偏后再验证，保证验证质量提高验证效率"},
        {id:7,text:"帮助企业建立可执行的日常操作规程及管理制度"},
        {id:8,text:"帮助企业形成符合新版GSP要求的必备验证控制文档"},
        {id:9,text:"验证全程原始数据自动生成、自动在系统保存，满足新版GSP不允许导出导入数据并可追溯的要求，为质管负责人提供验证项目远程实时监控确认功能"},
    ],
} 
export const zizhi = {
    title:"资质介绍",
    title_p1:"北京中科希望物联网络科技有限公司集合各领域专家（互联网云技术专家、物联网技术专家、数据安全专家、验证技术专家等 ），遵照新版GSP管理要求，自主研发和运营药品冷链全程温湿度可追溯云平台和监测系统，自主研发和生产智能化内嵌式监测设备和验证设备，拥有数十项自主知识产权；",
    title_p2:"北京中科物联是中关村高新技术企业，国家级高新技术企业；",
    title_p3:"北京中科物联还具有覆盖全国的技术支持能力和售后服务体系，全面解决药企的后顾之忧。",
    title_imgSrc:require("@/assets/images/about/pic_aboutme_1.jpg"),
}
export const qiye = {
    title:"企业荣誉",
    title_p1:"北京中科希望物联网络科技有限公司中国医药商业协会会员企业，验证云平台和专属定制模板，获得“十佳医药供应链技术创新应用案例”荣誉《集团用户整体验证解决方案》、《支持用户专属模板的验证解决方案》",
    title_imgSrc1:require("@/assets/images/about/pic_aboutme_2.jpg"),
    title_imgSrc2:require("@/assets/images/about/pic_aboutme_3.jpg"),
}
export const kehu = {
    title:"典型客户",
    title_imgSrc:require("@/assets/images/about/kehuIcon.jpg"),
}