import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from "@/api/home";
import './version'
// import imgSrc from "@/api/home";

// import "@/utils/flexible"
// import "@/utils/rem"
// import "@/utils/baidu_tongji"
// import "@/utils/cs_online"
import './assets/css/global.css'

Vue.config.productionTip = false
Vue.prototype.$api = api

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
