/**
 * 
    方法和参数在各组件中不共享，虽然组件调用了mixins并将其属性合并到自身组件中来了，
    但是其属性只会被当前组件所识别并不会被共享，也就是其他组件无法从当前组件中获取到mixins中的数据和方法。
 *
    引入mixins后组件会对其进行合并，将mixins中的数据和方法拓展到当前组件中来，在合并的过程中会出现冲突
    值为对象(components、methods 、computed、data)的选项，混入组件时选项会被合并，键冲突时优先组件，组件中的键会覆盖混入对象的
    值为函数(created、mounted)的选项，混入组件时选项会被合并调用，混合对象里的钩子函数在组件里的钩子函数之前调用   
**/

export const groupsMinxins = {
      mounted() {
        window.addEventListener("scroll", this.scrolling);
      },
      methods: {
        toTabContent(index) {
          this.cur = index;
          this.$refs[`tabItem${index}`].scrollIntoView({
            behavior: "smooth", // 平滑过渡
            block: "start", // 上边框与视窗顶部平齐。默认值
          });
        },
    
        scrolling() {
          // console.log;
          // const 
          // 滚动条距文档顶部的距离
          let scrollTop =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop;
          // 滚动条滚动的距离
          let scrollStep = scrollTop - this.oldScrollTop;
          // console.log("header 滚动距离 ", scrollTop);
          const scorllh = ((document.body.clientWidth/2.917)+145).toFixed(1)
          const scorllh1 = Number(scorllh) + Number(this.$refs.tabItem0.clientHeight) //600+ 1026
          const scorllh2 = scorllh1 + Number(this.$refs.tabItem1.clientHeight) //1626 3645
          
         
          if (scrollTop >= scorllh) {
            this.$refs.tabTitle?.classList.add("fixedStyle");
            this.isTab = true;
          } else {
            this.$refs.tabTitle?.classList.remove("fixedStyle");
            this.isTab = false;
          }
          if (scrollTop <= scorllh1) {
            this.cur = 0;
          }
          if (scrollTop > scorllh1 && scrollTop < scorllh2) {
            this.cur = 1;
          }
          if (scrollTop > scorllh2) { 
            this.cur = 2;
          }
          // 更新——滚动前，滚动条距文档顶部的距离
          this.oldScrollTop = scrollTop;
    
          //变量windowHeight是可视区的高度
          let windowHeight =
            document.documentElement.clientHeight || document.body.clientHeight;
          //变量scrollHeight是滚动条的总高度
          let scrollHeight =
            document.documentElement.scrollHeight || document.body.scrollHeight;
    
          //滚动条到底部的条件
          if (scrollTop + windowHeight == scrollHeight) {
            // console.log("header  你已经到底部了");
          }
          if (scrollStep < 0) {
            // console.log("header 滚动条向上滚动了！");
          } else {
            // console.log("header  滚动条向下滚动了！");
            // 判断是否到了最顶部
            if (scrollTop <= 0) {
              // console.log("header 到了最顶部");
            }
          }
        },
        beforeDestroy() {
          window.removeEventListener("scroll", this.scrolling);
        },
    
      },
}