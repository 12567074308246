// 集团化管理
export const item1 = {
    logo:require("@/assets/images/terrace/集团化管理.png"),
    title:"集团化管理",
    title1:"功能介绍",
    p1:"中科物联温度监测系统，帮助企业实现分公司、分仓、门店的高效统一管理，包括：",
    textList:[
        {id:1,text:"可以帮助集团公司总部对分公司、分仓或门店的仓库、冷链设备和冷链运输过程中的温湿度数据和设备运行状况，随时进行异地监测和管理；"},
        {id:2,text:"我们在对药企的设施设备进行验证时，集团总部可以通过云平台查看各分仓库房的验证过程和验证结果。"}
    ],
    title2:"功能特点",
    textList2:[
        {id:1,text:"统一覆盖仓储和冷链运输全过程的监测管理；"},
        {id:2,text:"按监管范围分配不同人员查看不同仓库或冷链设备，实现精细化管理；"},
        {id:3,text:"支持电脑、手机、Pad等多种便捷的查看方式。"},
    ]
}
// 全程数据监测
export const item2 = {
    logo:require("@/assets/images/terrace/全程数据监测.png"),
    title:"全程数据监测",
    title1:"功能介绍",
    p1:"中科物联温度监测系统，帮助企业实现对药品、医疗器械（含体外诊断试剂）在存储和运输过程温度的有效监测，包括：",
    textList:[
        {id:1,text:"在各个仓库存储过程中的温湿度数据；"},
        {id:2,text:"实时跟踪每辆冷藏车或每个保温箱在运输过程的温度数据；"},
        {id:3,text:"对每辆冷藏车或每个保温箱运输过程的详请历史追溯查询，包含：发货方／收货方、启运时间／抵达时间、运输过程最高／最低温值等；"},
        {id:4,text:"跟踪每辆冷藏车或每个保温箱运输过程GPS轨迹。"}
    ],
    title2:"功能特点",
    textList2:[
        {id:1,text:"温度数据的实时采集，上传、查看；"},
        {id:2,text:"支持电脑、手机、Pad等多种便捷的查看方式；"},
        {id:3,text:"多地数据备份，监测数据保存5年以上；"},
        {id:4,text:"运输抵达后可立即打印全程温度数据交付收货方。"},
    ]
}
// 风险预报警防控
export const item3 = {
    logo:require("@/assets/images/terrace/风险预警防控.png"),
    title:"风险预报警防控",
    title1:"功能介绍",
    p1:"中科物联温度监测系统通过预警机制，可以灵敏、准确的洞察超标风险前兆，主动出击，防患于未然。",
    textList:[
        {id:1,text:"可根据业务需求，自定义调整预警值，不同监测对象可分别设置。"},
        {id:2,text:"出现预警情况时，可以立即通过短信、微信、电话方式向指定接收人发送通知。"},
        {id:3,text:"超过预警线发生报警情况时，系统根据报警持续时间的长短以及岗位管理级别的不同，发送报警短信；"},
        {id:4,text:"监测到报警结束后立即发送恢复通知。"}
    ],
    title2:"功能特点",
    textList2:[
        {id:1,text:"从预警→报警→恢复通知，实现完整的闭环管理；"},
        {id:2,text:"分为三级的动态报警机制，可根据报警持续时间通知不同的负责人，避免过度打扰的同时保证重要信息不遗漏。"},
        {id:3,text:"支持移动、电信、联通手机号，采用短信+微信+电话的组合通知方式，送到率高达99.9%以上。"},
    ]
}
// 报警处理追溯
export const item4 = {
    logo:require("@/assets/images/terrace/报警处理追溯.png"),
    title:"报警处理追溯",
    title1:"功能介绍",
    p1:"中科物联温湿度监测系统，可对储运过程每一个温湿度的超标报警进行有效跟踪，包括：",
    textList:[
        {id:1,text:"跟踪储运过程每个超标报警的报警位置、报警类型、报警触发时间、报警持续时间、报警处理状态等；"},
        {id:2,text:"跟踪储运过程每个超标报警的处理响应时间、报警原因、报警处理方法、报警处理人等；"},
        {id:3,text:"对储运过程的每个超标报警历史或处理记录进行历史追溯查询；"},
    ],
    title2:"功能特点",
    textList2:[
        {id:1,text:"可追溯的报警发生和处理记录，便于复查和总结问题，改进操作流程；"},
        {id:2,text:"提供详细的发送记录，可用于追溯留档。"},
        {id:3,text:"多地数据备份，报警数据保存5年以上。"},
    ]
}