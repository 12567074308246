<template>
  <div class="wrapper">
    <div class="banner">
      <img src="@/assets/images/news/news_head.jpg" alt="" />
    </div>
    <div class="news_list">
      <div class="newslist_box" v-for="item in newsList" :key="item.id">
        <div class="newslist_con">
          <!-- <h3></h3> -->
          <span>{{ item.createTime }}</span>
          <p>{{ item.name }}</p>
          <div class="btn" @click="toDetail(item.id)">查看详情 &gt;</div>
        </div>
        <div class="newslist_pic">
          <img :src="imgSrc + item.img" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { imgSrc } from '@/api/home'
export default {
  name:'newsIndex',
  data() {
    return {
      newsList: [],
      newData0: [],
      newData1: [],
      imgSrc
    }
  },
  mounted() {
    this.getNewsList()
  },
  methods: {
    async getNewsList() {
      await this.$api.getNews0().then(res => {
        this.newsList = res.datas
      })
    },
    toDetail(id) {
      let routeData = this.$router.resolve({
        path: `/news/detail/${id}`
      })

      //必要操作，否则不会打开新页面
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style lang="less" scoped>
.wrapper {
  .banner {
    img {
      width: 100vw;
      height: calc(width / 6.4);
    }
  }
  .news_list {
    width: 1200px;
    min-height: 600px;
    margin: 0 auto;
    padding: 20px 0 50px;
    .newslist_box {
      width: 1200px;
      height: 170px;
      padding: 40px 0;
      border-bottom: 1px solid #dedede;
      .newslist_con {
        width: 700px;
        float: left;
        padding: 0 50px;
        text-align: left;
        h3 {
          color: #333e4c;
          font-size: 24px;
          font-weight: bold;
          height: 35px;
        }
        span {
          display: block;
          font-size: 15px;
          color: #999;
        }
        p {
          font-size: 16px;
          color: #666;
          line-height: 24px;
          padding: 20px 0;
        }
        div {
          color: #333;
          height: 30px;
          text-decoration: none;
        }
        .btn {
          cursor: pointer;
        }
      }
      .newslist_pic {
        width: 400px;
        height: 200px;
        float: left;
        img {
          margin: 0;
          padding: 0;
          width: 100%;
          height: 100%;
          border: none 0;
        }
      }
    }
  }
}
</style>