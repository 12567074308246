<template>
  <div class="mark" @click="showModel()">
    <!-- 集团化管理 -->

    <div class="content" v-if="modelId === 1">
      <div class="content-main">
        <div class="content-main_logo">
          <img :src="item1.logo" alt="" loading="lazy"/>
        </div>
        <div class="title">{{ item1.title }}</div>
        <div class="title1">{{ item1.title1 }}</div>
        <p>{{ item1.p1 }}</p>
        <ol>
          <li v-for="item in item1.textList" :key="item.id">
            {{ item.text }}
          </li>
        </ol>
        <div class="title1">{{ item1.title2 }}</div>
        <ol>
          <li v-for="item in item1.textList2" :key="item.id">
            {{ item.text }}
          </li>
        </ol>
      </div>
      <a class="cd-popup-close" @click="showModel()">X</a>
    </div>

    <!-- 全程数据监测 -->

    <div class="content" v-if="modelId === 2">
      <div class="content-main">
        <div class="content-main_logo">
          <img :src="item2.logo" alt="" loading="lazy"/>
        </div>
        <div class="title">{{ item2.title }}</div>
        <div class="title1">{{ item2.title1 }}</div>
        <p>{{ item2.p1 }}</p>
        <ol>
          <li v-for="item in item2.textList" :key="item.id">
            {{ item.text }}
          </li>
        </ol>
        <div class="title1">{{ item2.title2 }}</div>
        <ol>
          <li v-for="item in item2.textList2" :key="item.id">
            {{ item.text }}
          </li>
        </ol>
      </div>
      <a class="cd-popup-close" @click="showModel()">X</a>
    </div>

    <!-- 风险预报警防控 -->

    <div class="content" v-if="modelId === 3">
      <div class="content-main">
        <div class="content-main_logo">
          <img :src="item3.logo" alt="" loading="lazy" />
        </div>
        <div class="title">{{ item3.title }}</div>
        <div class="title1">{{ item3.title1 }}</div>
        <p>{{ item3.p1 }}</p>
        <ol>
          <li v-for="item in item3.textList" :key="item.id">
            {{ item.text }}
          </li>
        </ol>
        <div class="title1">{{ item1.title2 }}</div>
        <ol>
          <li v-for="item in item3.textList2" :key="item.id">
            {{ item.text }}
          </li>
        </ol>
      </div>
      <a class="cd-popup-close" @click="showModel()">X</a>
    </div>

    <!-- 报警处理追溯 -->

    <div class="content" v-if="modelId === 4">
      <div class="content-main">
        <div class="content-main_logo">
          <img :src="item4.logo" alt="" loading="lazy"/>
        </div>
        <div class="title">{{ item4.title }}</div>
        <div class="title1">{{ item4.title1 }}</div>
        <p>{{ item4.p1 }}</p>
        <ol>
          <li v-for="item in item4.textList" :key="item.id">
            {{ item.text }}
          </li>
        </ol>
        <div class="title1">{{ item1.title2 }}</div>
        <ol>
          <li v-for="item in item4.textList2" :key="item.id">
            {{ item.text }}
          </li>
        </ol>
      </div>
      <a class="cd-popup-close" @click="showModel()">X</a>
    </div>
  </div>
</template>

<script>
import { item1, item2, item3, item4 } from "@/utils/config/terraceModel";

export default {
  props: {
    modelId: {
      type: Number,
    },
  },
  data() {
    return {
      item1,
      item2,
      item3,
      item4,
    };
  },
  mounted() {},
  watch: {
    modelId(val) {
      this.modelId = val;
    },
    immediate: true,
  },
  methods: {
    showModel() {
      this.$emit("showModel", false);
    },
  },
};
</script>

<style lang="less" scoped>
.mark {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    text-indent: 0em;
    width: 1200px;
    display: inline-block;
    height: auto;
    padding: 20px;
    background: #fff;
    border-radius: 30px;
    text-align: left;
    box-shadow: 0 0 20px rgb(0 0 0 / 20%);
    position: relative;
    -webkit-backface-visibility: hidden;
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -ms-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    .content-main {
      width: 77%;
      margin: 0 auto;
      font-size: 16px;
      img {
        width: 300px;
        height: 200px;
      }
      .content-main_logo {
        display: flex;
        justify-content: center;
      }
      .title {
        margin-top: 30px;
        font-size: 26px;
        font-weight: bold;
      }
      .title1 {
        margin-top: 30px;
        font-size: 22px;
        font-weight: bold;
      }
      p {
        font-size: 16px;
      }
      ol {
        padding-inline-start: 16px;
      }
    }
    a {
      color: #333;
      font-size: 18px;
      font-weight: bold;
      text-decoration: none;
    }
    .cd-popup-close {
      position: absolute;
      right: 30px;
      top: 30px;
      z-index: 10;
      width: auto;
      // height: 1.25rem;
      display: block;
      font-size: 19px;
      cursor: pointer;
    }
  }
}
</style>