<template>
  <div class="wrapper">
    <div class="main">
      <div class="title">{{ itemData.title }}</div>
      <div class="banner">
        <img v-if="itemData.maxImg" :src="itemData.maxImg" alt="" loading="lazy" style="max-width:1000px"/>
        <img v-if="itemData.maxImg2" :src="itemData.maxImg2" alt="" loading="lazy" />
      </div>
      <div v-if="itemData.p1" class="title1">{{ itemData.p1 }}</div>
      <p class="p2em" v-show="itemData.p1TextList&&itemData.p1TextList.length>0" v-for="item,index in itemData.p1TextList" :key="index+'p1t'">{{item}}</p>
      <div v-show="itemData.maxImgList&&itemData.maxImgList.length>0" v-for="item,index in itemData.maxImgList" :key="index+'ma'">
        <img :src="item.url" alt="" loading="lazy" :style="item.imgStyle?item.styles:''">
      </div>
      <div 
        v-show="itemData.p2List&&itemData.p2List.length>0" 
        v-for="item,index in itemData.p2List" 
        :key="index+'p2'">
        <div class="title1">{{ item.p2 }}</div>
        <img v-if="item.imgSrc" :src="item.imgSrc" :style="item.imgStyle?item.styles:''" loading="lazy" />
        <!-- :class="index!==itemData.p2List.length-1 ? 'p2em':''" -->
        <p class="p2em">{{ item.p2Text }}</p>
      </div>
      <div v-if="itemData.p2Img&&itemData.p2Img.length>0" class="fanwei">
        <div v-for="item,index in itemData.p2Img" :key="index+'p2m'">
          <img :src="item.imgSrc" loading="lazy" />
          <p>{{ item.name }}</p>
        </div>
      </div>
      <div v-if="itemData.p3" class="title1">{{ itemData.p3 }}</div>
      <p v-if="itemData.p3H" class="xitong-p">{{ itemData.p3H }}</p>
      <ol v-if="itemData.p3Text&&itemData.p3Text.length>0">
        <li v-for="item,index in itemData.p3Text" :key="index+'p3t'">{{ item }}</li>
      </ol>
      <div v-if="itemData.p3Text1&&itemData.p3Text1.length>0" class="p2em">
        <p v-for="item,index in itemData.p3Text1" :key="index+'p3t1'">{{ item }}</p>
      </div>
      <div v-if="itemData.p4" class="title1">{{ itemData.p4 }}</div>
      <div v-show="itemData.p4Text&&itemData.p4Text.length>0" class="imgList" :style="item.imgStyle?item.styles.parent:''" v-for="item,index in itemData.p4Text" :key="index+'p4'">
        <p :style="item.textStyle || ''">{{ item.name }}</p>
        <img v-if="item.imgSrc" :src="item.imgSrc" alt="" loading="lazy" :style="item.imgStyle? item.styles.cur:''" />
      </div>
         
          <!-- ------------------- -->
    <div v-if="isShowHonor" class="tab-content">
              <!-- <div class="item">
                <div class="title">{{ zizhi.title }}</div>
                <p>{{ zizhi.title_p1 }}</p>
                <p>{{ zizhi.title_p2 }}</p>
                <p>{{ zizhi.title_p3 }}</p>
                <div v-for="item,index in honorData.honorImgList.slice(0,6)" 
                  :key="index" >
                  <img
                  :src="item.imgSrc" 
                  alt="" 
                  style="width:100%;height:100%" 
                />
                </div>
              </div>
              <div class="item">
                <div class="title">{{ qiye.title }}</div>
                <p>{{ qiye.title_p1 }}</p>
                <div class="qiyeImg">
                  <img :src="qiye.title_imgSrc1" alt="" />
                  <img :src="qiye.title_imgSrc2" alt="" />
                </div>
              </div> -->
              <div class="item" ref="tabItem1">
                <div class="title">{{ honorData.title }}</div>
                <!-- <p>{{ honorData.pOne }}</p>
                <p>{{ honorData.pTwo }}</p> -->
                <p v-for="item,i in honorData.pTextList" :key="i">{{item}}</p>
                <img 
                  v-for="item,index in honorData.honorImgList" 
                  :key="index+'honor'" 
                  :src="item.imgSrc" 
                  alt="" 
                  style="object-fit: contain;" 
                  :class="index===8||index===9?'honorImg':''"
                  loading="lazy"
                />
              </div>
              <div class="item">
                <div class="title">{{ kehu.title }}</div>
                <img class="kehuImg" :src="kehu.title_imgSrc" alt="" />
              </div>
          </div>
          <!-- ------------------- -->

      <div class="title1">{{ itemData.p5 }}</div>
    </div>


    <div class="contact">
      <PhoneContact />
    </div>
  </div>
</template>

<script>
import PhoneContact from "@/components/phoneContact";
import { zizhi, qiye, kehu } from '@/utils/config/about'

import { honorData } from '@/utils/config/home'
export default {
  components: {
    PhoneContact,
  },
  props: {
    itemData: {
      type: Object,
    },
    isShowHonor:{
      type:Boolean,
      default:()=>{
        return true
      }
    }
  },
  data() {
    return {
      honorData,

      zizhi,
      qiye,
      kehu,
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.p2em{
  text-indent: 2em;
}
.wrapper {
   background-color: #f3f7fa;
  .main {
    width: 1200px;
    margin: 0 auto;
    text-align: left;
   
    .title {
      padding: 30px 0px;
      font-size: 36px;
      font-weight: bold;
    }
    // .banner {
    //   img {
    //     // width: 100;
    //     // height: 600px;
    //   }
    // }
    .title1 {
      margin-top: 30px;
      font-size: 24px;
      font-weight: bold;
    }
    p {
      font-size: 16px;
      line-height: 26px;
    }
    .fanwei {
      display: flex;
      justify-content: space-between;
      img {
        width: 260px;
        height: 160px;
      }
      p {
        text-align: center;
      }
    }
    .xitong-p {
      font-size: 16px;
      font-weight: bold;
    }
    ol {
      list-style-type: square;
      li {
        font-size: 16px;
        line-height: 26px;
      }
    }
    .imgList {
      font-size: 16px;
      font-weight: bold;
      img {
       width: 1000px;
       height: 583px;
      }
    }
  }
}
.tab-content {
      width: 1200px;
      margin: 0 auto;
      padding-bottom: 60px;
      .item:first-child {
        margin-top: 0px;
      }
      .item {
        margin-top: 30px;
        text-align: left;
        .title {
          font-size: 24px;
          font-weight: bold;
          color: #333e4c;
        }
        p {
          color: #666;
          font-size: 16px;
          line-height: 30px;
          text-indent: 2em;
        }
        .title_h2 {
          margin-top: 30px;
          color: #2eb1c1;
          font-size: 20px;
        }
        ol {
          padding-inline-start: 16px;
          li {
            list-style: disc;
            padding-inline-start: 0px;
            color: #666;
            font-size: 16px;
            line-height: 30px;
          }
        }
        .qiyeImg {
          display: flex;
          justify-content: space-around;
        }
        .kehuImg {
          margin-top: 30px;
          width: 100%;
        }
      }
      .honorImg {
        margin: 0px 50px;
      }
    }
</style>