<template>
  <div class="wrapper">
    <div class="swiper-container" ref="mySwiper">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item) in swiperList"
          :key="item.id"
        >
          <img :src="item.imgUrl" />
          <!-- <div
            v-show="index === 0"
            class="swiper-btn"
            @click="$router.push({ path: item.routerUrl })"
          >
            了解更多内容
          </div> -->
        </div>
      </div>
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
import Swiper, { Pagination, Autoplay } from "swiper"; 
import "swiper/swiper-bundle.css";

Swiper.use([Pagination, Autoplay]);

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Swiper,
  },
  data() {
    return {
      swiperList: [
        {
          id: "0001",
          imgUrl: require("@/assets/images/home/jdt_1.jpg"),
          routerUrl: "",
        },
        {
          id: "0002",
          imgUrl: require("@/assets/images/home/jdt_2.jpg"),
          routerUrl: "",
        },
        {
          id: "0003",
          imgUrl: require("@/assets/images/home/jdt_3.jpg"),
          routerUrl: "",
        },
      ],
    };
  },
  mounted() {
    var mySwiper = new Swiper(".swiper-container", {
      //direction: 'vertical', // 垂直切换选项
      loop: true, // 循环模式选项
      autoplay: {
        delay: 3000, //自动切换的时间间隔，单位ms
        disableOnInteraction: false, //用户操作swiper之后，是否禁止autoplay
      },
      // 如果需要分页器
      pagination: {
        el: ".swiper-pagination",
        clickable: true, //设置小原店面可以切换
      },
    });
    mySwiper.el.onmouseover = function () {
      //鼠标放上暂停轮播
      mySwiper.autoplay.stop();
    };
    mySwiper.el.onmouseleave = function () {
      mySwiper.autoplay.start();
    };
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  .swiper-container {
    // min-width: 1519px;
    height: auto;
    position: relative;
    .swiper-wrapper {
      width: 100%;
      height: 100%;
      .swiper-slide {
        width: 100%;
        height: 100%;
        position: relative;
        img {
          display: inline-block;
          width: 100vw;
          height: calc( width / 2.91793);
          // height: 100%;
          object-fit: cover;
        }
        .swiper-btn {
          width: 30%;
          height: 16%;
          border: 2px solid #3f3f44;
          line-height: 36px;
          position: absolute;
          font-size: 16px;
          font-weight: bold;
          bottom: 23%;
          left: 30%;
        }
        .swiper-btn:hover {
          color: #f3f7fa;
          // border: 2px solid #f3f7fa;
          background-color: #00bba8;
          cursor: pointer;
        }
      }
    }
    .swiper-pagination {
      display: flex;
      justify-content: center;
      bottom: 20px;
      /deep/.swiper-pagination-bullet {
        width: 20px;
        height: 20px;
        margin: 2px;
        border: 3px solid #060606;
        border-radius: 0;
        background-color: #060606;
        overflow: hidden;
        font-size: 0;
        cursor: pointer;
        zoom: 1;
      }
      /deep/.swiper-pagination-bullet-active {
        background-color: #00bba8;
      }
    }
  }
}
</style>