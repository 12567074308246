<template>
  <div class="wrapper">
    <div class="banner">
      <!-- <img src="" alt="" /> -->
    </div>
    <div class="tabNav">
      <div class="tabNav-wra" ref="tabTitle">
        <ul class="tabNav-tit">
          <li
            v-for="(item, index) in groupsList"
            :key="item.id"
            @click="toTab(index)"
            :class="cur == index ? 'tabActive' : 'hiddenTit'"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>

      <ul class="hiddenTit" v-show="isTab"></ul>

      <div class="tab-content">
        <div class="list_content">
          <div
            class="item"
            v-for="(item, index) in groupsList"
            :key="item.id"
            :ref="'tabItem' + index"
          >
            <div class="title">
              <h3>{{ item.name }}</h3>
              <p>
                {{ item.content }}
              </p>
            </div>
            <div
              class="box"
              v-for="item2 in item.products"
              :key="item2.id"
              @click="toDetail(item2.id)"
            >
              <img :src="imgSrc + item2.img" loading="lazy" />
              <h4>{{ item2.name }}</h4>
              <p>{{ item2.typeName }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { imgSrc } from "@/api/home";
export default {
  name:'groupsItem',
  props: {
    curIndex: {
      type: Number,
    },
    groupsList: [],
  },
  data() {
    return {
      cur: 0,
      isTab: false,
      imgSrc,
    };
  },
  mounted() {
    // this.cur = this.curIndex;

    this.cur = this.$store.state.navList.navCurIndex;
    this.toTabContent(this.curIndex);
    // console.log("this.cur",this.cur);
    window.addEventListener("scroll", this.scrolling);
  },
  beforeUpdate() {
    window.removeEventListener("scroll", this.scrolling);
  },
  updated() {
    this.toTabContent(this.curIndex);
    window.addEventListener("scroll", this.scrolling);
  },
  watch: {
    curIndex: {
      handler(val) {
        this.curIndex = val;
      },
    },
    modelList: {
      handler(val) {
        if (val) {
          this.modelList = val;
        }
      },
      immediate: true,
    },
  },
  methods: {
    toDetail(id) {
      let routeData = this.$router.resolve({
        path: `/deviceDetail/${id}`,
      });
      window.open(routeData.href, "_blank");
    },
    toTab(index) {
      this.$emit('update:curIndex', index)
      this.$refs[`tabItem${index}`][0].scrollIntoView({
        behavior: "smooth", // 平滑过渡
        block: "start", // 上边框与视窗顶部平齐。默认值
      });
    },
    toTabContent(index) {
      // this.cur = index;
      this.$refs[`tabItem${index}`][0].scrollIntoView({
        behavior: "smooth", // 平滑过渡
        block: "start", // 上边框与视窗顶部平齐。默认值
      });
    },

    scrolling() {
      // 滚动条距文档顶部的距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop - this.oldScrollTop;
      // console.log("header 滚动距离 ", scrollTop);
      if (scrollTop >= 300) {
        this.$refs.tabTitle?.classList.add("fixedStyle");
        this.isTab = true;
      } else {
        this.$refs.tabTitle?.classList.remove("fixedStyle");
        this.isTab = false;
      }
      if (scrollTop <= 1032) {
        this.cur = 0;
      }
      if (scrollTop >= 1032 && scrollTop < 1732) {
        this.cur = 1;
      }
      if (scrollTop >= 1740 && scrollTop < 2000) {
        this.cur = 2;
      }
      if (scrollTop >= 2000) {
        this.cur = 3;
      }
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop;

      //变量windowHeight是可视区的高度
      let windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;

      //滚动条到底部的条件
      if (scrollTop + windowHeight == scrollHeight) {
        // console.log("header  你已经到底部了");
      }
      if (scrollStep < 0) {
        // console.log("header 滚动条向上滚动了！");
      } else {
        // console.log("header  滚动条向下滚动了！");
        // 判断是否到了最顶部
        if (scrollTop <= 0) {
          // console.log("header 到了最顶部");
        }
      }
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.scrolling);
    },
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  .banner {
    height: 260px;
    background: url("@/assets/images/monitor-device/banner.jpg") no-repeat;
    background-size: 100%;
  }
  .tabNav {
    .tabNav-wra {
      .tabNav-tit {
        margin: 0 auto;
        padding-top: 19px;
        width: 1200px;
        background-color: #fff;
        text-align: center;
        cursor: pointer;
        li {
          display: block;
          float: left;
          margin-left: 11px;
          padding: 0px 16px;
          width: auto;
          height: 44px;
          border: 1px solid #e3e1e2;
          border-bottom: 0;
          background-color: #5c6570;
          color: #e3e1e2;
          font-size: 16px;
          line-height: 44px;
          text-align: center;
          text-decoration: none;
        }
        .tabActive {
          display: block;
          float: left;
          margin-left: 11px;
          color: #333e4c;
          background-color: #f4f4f4;
          text-align: center;
          font-size: 16px;
          line-height: 44px;
          text-decoration: none;
        }
      }
    }

    .hiddenTit {
      width: 1200px;
      height: 63px;
      margin: 0 auto;
      padding-top: 16px;
    }
    .tab-content {
      .list_content {
        width: 100%;
        height: auto;
        display: inline-block;
        padding: 30px;
        background-color: #f4f4f4;
        .item {
          display: inline-block;
          width: 1200px;
          height: auto;
          padding: 20px 10px;
          margin: 0 auto;
          .title {
            padding-left: 15px;
            margin: 20px 10px;
            width: 1145px;
            background: url(" @/assets/images/list_title.jpg") no-repeat;
            line-height: 26px;
            text-align: left;
            h3 {
              margin-bottom: 0px;
              font-size: 24px;
            }
            p {
              margin: 0;
              font-size: 16px;
            }
          }
          .box {
            position: relative;
            float: left;
            margin: 10px;
            padding: 20px 15px;
            width: 185px;
            height: 225px;
            background: #fff;
            border-bottom: 1px solid #e2dee0;
            cursor: pointer;
            h4 {
              font-size: 20px;
              color: #333e4c;
            }
            p {
              font-size: 13px;
              color: #666;
            }
          }
          .box:hover {
            box-shadow: 0 13px 23px -6px rgb(0 0 0 / 50%);
            -webkit-box-shadow: 0 13px 23px -6px rgb(0 0 0 / 50%);
          }
        }
      }
    }
  }
}
.fixedStyle {
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;
  background-color: #fff;
}
</style>