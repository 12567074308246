// 医药行业

export const item1 = {
    title: "医药行业温湿度监测系统解决方案",
    maxImg: require("@/assets/images/mPlan-scene/mPlan-scene_8.jpg"),
    p1: "行业背景",
    p1TextList:[
        "医药经营企业方案涵盖仓储、冷链配送、终端网点存储等环节,需要对药品、医疗器械等各环节温湿度数据进行集中、统一管理。目前医药行业对药品、医疗器械存储环境的要求越来越高,依据最新版的《中华人民共和国药品管理法》(2019 版)。新版的《中华人民共和国药品管理法》强化了药品监管的两个重点环节,即药品购销道和仓储温湿度控制。药监局明确要求对药品仓库需要有历史环境监控数据,并纳入发证考核指标。",
        "为了满足上述需求,我司推出了一套合规、高效、安全的温湿度监测系统,对药品经营流通各环节的温湿度数据集中监测和规范管理,实现集团化、平台化的多层管理。",
    ],
    p2List:[
        {p2:'适用范围',p2Text: "仓库/冷库、冷柜、冷藏车、保温箱等",}
    ],
    p2Img: [
        { id: 1, name: "仓库/冷库", imgSrc: require("@/assets/images/mPlan-scene/mPlan-scene_3.jpg"), },
        { id: 2, name: "冷柜", imgSrc: require("@/assets/images/mPlan-scene/mPlan-scene_2.jpg"), },
        { id: 3, name: "冷藏车", imgSrc: require("@/assets/images/mPlan-scene/mPlan-scene_4.jpg"), },
        { id: 4, name: "保温箱", imgSrc: require("@/assets/images/mPlan-scene/mPlan-scene_1.jpg"), },
    ],

    p3: "系统特点",
    p3H: "中科物联温湿度自动监测系统功能特点",
    p3Text: [
        "集团公司总部可对分公司、分仓或门店的仓库、冷柜、保温箱、冷藏车运输过程中的温湿度数据和设备运行状况,随时进行异地监测和统一管理；",
        "全国各地仓库、冷柜、冷藏车、保温箱数据统一上传至云端存储,便于管理和查看;",
        "支持多路温湿度采集,可同时监测多个位置数据;",
        "自动监测温湿度数据,具有短信、微信、电话和现场报警功能;",
        "对开关门支持秒级监测,精确控制开关门操作规范;",
        "可实时显示设备的温湿度数据,可随时查看数据变化曲线、历史数据、超限数据等;",
        "支持手机、电脑、Pad多端同时在线监管,随时随地查看数据；",
        "监测数据支持断点续传功能,可自动补传数据;",
        "支持机载打印、蓝牙打印、外接连线打印多种方式;",
        "双路供电,大容量电池保证续航能力;",
        "数据存放安全,支持异地自动灾备;",
    ],

    p4: "系统拓扑图",
    p4Text: [
        { id: 1, name: "仓库/冷库监测", imgSrc: require("@/assets/images/mpItem/mpItem_3.jpg"), },
        { id: 2, name: "冷柜监测", imgSrc: require("@/assets/images/mpItem/mpItem_2.jpg"), },
        { id: 3, name: "冷藏车监测", imgSrc: require("@/assets/images/mpItem/mpItem_5.jpg"), },
        { id: 4, name: "保温箱监测", imgSrc: require("@/assets/images/mpItem/mpItem_1.jpg"), },
    ],

    p5: "联系我们"
}
// 疾控中心、血液中心、疫苗配送中心
export const item1_1 = {
    title: "疾控中心、血液中心",
    // maxImg: require("@/assets/images/mpItem/mp1-1/1.png"),
    p1: "疾控中心、血液中心、疫苗配送中心温湿度监测背景",
    p1TextList:[
        "《预防接种工作规范》、《疫苗流通和预防接种管理条例》、《疫苗存储和运输管理规范》、《血液运输标准》等国家规范要求中都对于疫苗、特殊药品、血液在管理、运输、保存方面都有严格的温湿度环要求。",
    ],
    maxImgList: [],
    p2List:[
        {p2:'适用范围',p2Text: "",}
    ],
    p2Img: [
        { id: 1, name: "存储使用环节", imgSrc: require("@/assets/images/mpItem/mp1-1/1.png"), },
        { id: 2, name: "运输环节", imgSrc: require("@/assets/images/mpItem/mp1-1/2.jpg"), },
        { id: 3, name: "运输环节", imgSrc: require("@/assets/images/mpItem/mp1-1/3.jpg"), },
    ],
    
    p3: "疫苗、血液冷链监测系统特点：",
    p3H: "疫苗冷链监测系统集采集终端、云平台、各个通讯环节于一体,具有以下特点：",
    p3Text: [
        "1、实时性,全天候自动上传温湿度数据,实现不间断监测和报警;",
        "2、真实性,监测数据直接上传云端平台,确保温湿度及疫苗相关数据的客观性和可靠性;",
        "3、安全性,系统可进行防病毒入侵等安全策略管理;",
        "4、便利性,设备集采集、存储、传输、处理于一体,可随时随地了解疫苗温湿度数据和设备工作状况。"
    ],

    p4: "系统拓扑图",
    p4Text: [
        { id: 1, name: "中科物联温湿度自动监测系统解决方案", imgSrc: require("@/assets/images/mpItem/mp1-1/4.jpg"), },
        { id: 2, name: "冷藏运输车系列", imgSrc: require("@/assets/images/mpItem/mp1-1/5.jpg"), },
    ],

    p5: "联系我们"
}
// 药品经营

export const item1_2 = {
    title: "药品经营企业",
    maxImg: require("@/assets/images/mpItem/mp1-2/1.png"),
    p1TextList:[
        '近日,市场监管总局公布《药品经营和使用质量监督管理办法》(国家市场监督管理总局令第84号）,自2024年1月1日起施行。',
        '第四十一条　药品储存、运输应当严格遵守药品经营质量管理规范的要求,根据药品包装、质量特性、温度控制等要求采取有效措施,保证储存、运输过程中的药品质量安全。冷藏冷冻药品储存、运输应当按要求配备冷藏冷冻设施设备,确保全过程处于规定的温度环境,按照规定做好监测记录。',
    ],
    maxImgList: [],
    p2List:[
        {p2:'药品经营企业应用场景',p2Text: "",}
    ],
    p2Img: [
        { id: 1, name: "药品集散中心", imgSrc: require("@/assets/images/mpItem/mp1-2/2.jpg"), },
        { id: 2, name: "冷藏运输车", imgSrc: require("@/assets/images/mpItem/mp1-2/3.jpg"), },
        { id: 3, name: "保温箱", imgSrc: require("@/assets/images/mpItem/mp1-2/4.jpg"), },
    ],
    
    p3: "",
    p3H: "",
    p3Text: [],

    p4: "中科物联药品经营企业温湿度监控方案",
    p4Text: [
        { id: 1, name: "药品集散中心  有线设备+无线设备全面覆盖", imgSrc: require("@/assets/images/mpItem/mp1-2/5.jpg"), },
        { id: 2, name: "冷藏运输车  车载采集器+温湿度探头或采集器+无线检测仪灵活搭配", imgSrc: require("@/assets/images/mpItem/mp1-2/6.jpg"), },
        { 
            id: 3, 
            name: "保温箱 外部嵌入式和内部放置式,检测设备可独立与平台通信,也有为大客户专门定制的集群统一管理方式。", 
            imgSrc: require("@/assets/images/mpItem/mp1-2/7.png"), 
            imgStyle:true ,
            styles:{
                parent:'',cur:'width:auto!important;'
            }
        },
        { id: 4, name: "", imgSrc: require("@/assets/images/mpItem/mp1-2/8.jpg"), },
    ],

    p5: "联系我们"
}
// 药品零售企业

export const item1_3 = {
    title: "药品零售企业",
    maxImg: require("@/assets/images/mpItem/mp1-3/1.png"),
    maxImg2: require("@/assets/images/mpItem/mp1-3/2.png"),
    p1: "常见需要放冷柜的药品",
    p1TextList:[
        '1.注射用药：一些需要注射的药物,如某些抗生素、免疫调节剂等,通常需要在低温下储存以保持其稳定性和有效性。',
        '2.生物制品：包括疫苗、血液制品和生物技术制品等,在冷藏条件下储存以保持其活性和有效性。',
        ' 3.高敏感度药物：某些药物对温度变化非常敏感,需要在冷藏条件下保存以延长其有效期和防止分解。例如,某些眼药水和鼻喷剂。',
        '4.口服液体药品：一些口服液体药品,如某些抗生素、抗生素悬浮液等,可能需要在冷藏条件下储存,以延长其使用寿命和保药物稳定性。',
        '5.特殊药物：某些特殊药物,如某些基因治疗药物、罕见病药物等,可能需要低温冷藏条件下储存,以保持其活性和有效性。'
    ],
    maxImgList: [],
    p2List:[
        {p2:'中科物联药品零售企业温湿度监控方案',p2Text: "",}
    ],
    p2Img: [
        { id: 1, name: "", imgSrc: require("@/assets/images/mpItem/mp1-3/3.jpg"), },
        { id: 2, name: "", imgSrc: require("@/assets/images/mpItem/mp1-3/4.jpg"), },
        { id: 3, name: "送货保温箱", imgSrc: require("@/assets/images/mpItem/mp1-3/5.jpg"), },
    ],
    p3: "",
    p3H: "",
    p3Text: [],

    p4: "中科物联药品经营企业温湿度监控方案",
    p4Text: [
        { id: 1, name: "有线、wifi、4G多种方式的温湿度监测设备,并且配备了后备不间断电源,保证停电状态下数据也能正常记录。", imgSrc: require("@/assets/images/mpItem/mp1-3/6.jpg"), },
        { 
            id: 2, 
            name: "送药保温箱", 
            imgSrc: require("@/assets/images/mpItem/mp1-3/7.png"),
            imgStyle:true ,
            styles:{
                cur:'width:auto!important;'
            } 
        },
    ],

    p5: "联系我们"
}

// 药品生产企业
export const item1_4 = {
    title: "药品生产企业",
    maxImg: require("@/assets/images/mpItem/mp1-4/1.png"),
    p1:"医药行业温湿度监测背景", 
    p1TextList:[
        "目前国家对医药行业药品生产、存储环境要求越来越严格,根据最新版《中华人民共和国药品管理法》,加强了药品监管的两个重点环节,药品存储和销售渠道的温湿度控制。",
        "药监局明确要求对药品仓库有历史环境监控数据,并纳入发证考核指标,并且严禁监测设备数据作假,一经发现,立即吊销相关资质。药品是人类用于预防、治疗疾病的特殊商品,存储环境的好坏直接影响药品质量,从而降低治疗效果,甚至决定病人生命。所以在GSP/GMP认证、《药品经营许可证》中都明确要求厂家安装温湿度监测系统。",
    ],
    maxImgList: [],
    p2List:[
        {p2:'药品生产企业应用场景',p2Text: "原材料仓、生产车间、库房、运输车",}
    ],
    p2Img: [
        { id: 1, name: "原材料仓", imgSrc: require("@/assets/images/mpItem/mp1-4/2.jpg"), },
        { id: 2, name: "生产车间", imgSrc: require("@/assets/images/mpItem/mp1-4/3.jpg"), },
        { id: 3, name: "库房", imgSrc: require("@/assets/images/mpItem/mp1-4/4.jpg"), },
        { id: 4, name: "运输车", imgSrc: require("@/assets/images/mpItem/mp1-4/5.jpg"), },
    ],
    
    p3: "",
    p3H: "",
    p3Text: [],

    p4: "中科物联温湿度自动监测系统解决方案",
    p4Text: [
        { id: 1, name: "仓库和生产车间系列", imgSrc: require("@/assets/images/mpItem/mp1-4/6.jpg"), },
        { id: 2, name: "冷藏运输车系列", imgSrc: require("@/assets/images/mpItem/mp1-4/7.jpg"), },
    ],

    p5: "联系我们"
}
// 医院
export const item1_5 = {
    title: "医院",
    // maxImg: require("@/assets/images/mpItem/mp1-5/1.png"),
    p1: "医院温湿度监测背景",
    p1TextList:[
        "可应用于医院医学实验室(检验科）,全国医用临床检验实验室和体外诊断系统标准化技术委员会(SAC/TC136）转化ISO标准————医学实验室质量和能力认可准则(即ISO15189：2012）对医学实验室的环境温湿度和冰箱的温湿度数据监控提出了明确的要求。-医院温湿度监控系统"
    ],
    maxImgList: [
        {id:0,name:'',url:require("@/assets/images/mpItem/mp1-5/1.png"),
        imgStyle:true,styles:'width:1000px'}
    ],
    p2List:[
        {p2:'药品生产企业应用场景',p2Text: "原材料仓、生产车间、库房、运输车",}
    ],
    p2Img: [],
    
    p3: "医院温湿度监测系统方案-有线+无线组网监控系统",
    p3H: "WIFI无线温湿度记录仪是智拓仪器新一代设备,现场WIFI覆盖即可实施远程上传数据,同时有线设备覆盖无线死角,本地数据存储65000条数据,可选配大容量UPS电池。信号未覆盖的情况下,自行存储数据,恢复后,自动续传。",
    p3Text: [
        "(1）仪器会通过WIFI功能将实时温湿度数据传送到云平台通过电脑观看。",
        "(2）仪器自动连接网络功能：成功连接网络后,设备均会自动连接上传",
        "(3）内置声光报警",
        "(4）内置存储器,未连接信号会自动存储数据,恢复信号后,自动补传数据",
        "(5）外形简洁大方,性能实用可靠。"
    ],

    p4: "",
    p4Text: [
        { id: 1, name: "", imgSrc: require("@/assets/images/mpItem/mp1-5/2.jpg"), },
    ],

    p5: "联系我们"
}
// 医疗机构
export const item2 = {
    title: "医疗机构温湿度监测系统解决方案",
    maxImg: require("@/assets/images/mPlan-scene/mPlan-scene_7.jpg"),
    p1: "行业背景",
    p1TextList:[
        "当前医疗机构科室较多,监测场景相对复杂、分散,监测要求差异化,并且试剂、样本等的储运,特别是冷链环节存在实时性差、监管脱节:取证困难无法确定责任等问题,对于监测设备的要求需要具备安装简易、数量多、精度高,我司深耕构建温湿度监测系统多年,沉淀多年研发经验,打造合规、高效、安全的医疗机构温湿度监测系统,实现对 药库、药房、病房、检验科、药剂科等环境及设备进行全方位差异化监测和集中管理 ",
    ],
    p2List:[
        {p2:'适用范围',p2Text: "医院药房、医用冷柜、医用冷藏车、医用保温箱等",}
    ],
    p2Img: [
        { id: 1, name: "医院药房", imgSrc: require("@/assets/images/mPlan-scene/mPlan-scene_3.jpg"), },
        { id: 2, name: "医用冷柜", imgSrc: require("@/assets/images/mPlan-scene/mPlan-scene_2.jpg"), },
        { id: 3, name: "医用冷藏车", imgSrc: require("@/assets/images/mPlan-scene/mPlan-scene_4.jpg"), },
        { id: 4, name: "医用保温箱", imgSrc: require("@/assets/images/mPlan-scene/mPlan-scene_1.jpg"), },
    ],

    p3: "系统特点",
    p3H: "中科物联温湿度自动监测系统功能特点",
    p3Text: [
        "集团公司总部可对分公司、分仓或门店的仓库、冷柜、保温箱、冷藏车运输过程中的温湿度数据和设备运行状况,随时进行异地监测和统一管理；",
        "全国各地仓库、冷柜、冷藏车、保温箱数据统一上传至云端存储,便于管理和查看;",
        "支持多路温湿度采集,可同时监测多个位置数据;",
        "自动监测温湿度数据,具有短信、微信、电话和现场报警功能;",
        "对开关门支持秒级监测,精确控制开关门操作规范;",
        "可实时显示设备的温湿度数据,可随时查看数据变化曲线、历史数据、超限数据等;",
        "支持手机、电脑、Pad多端同时在线监管,随时随地查看数据；",
        "监测数据支持断点续传功能,可自动补传数据;",
        "支持机载打印、蓝牙打印、外接连线打印多种方式;",
        "双路供电,大容量电池保证续航能力;",
        "数据存放安全,支持异地自动灾备;",
    ],

    p4: "系统拓扑图",
    p4Text: [
        { id: 1, name: "医院药房监测", imgSrc: require("@/assets/images/mpItem/mpItem_3.jpg"), },
        { id: 2, name: "医用冷柜监测", imgSrc: require("@/assets/images/mpItem/mpItem_2.jpg"), },
        { id: 3, name: "医用冷藏车监测", imgSrc: require("@/assets/images/mpItem/mpItem_5.jpg"), },
        { id: 4, name: "医用保温箱监测", imgSrc: require("@/assets/images/mpItem/mpItem_1.jpg"), },
    ],

    p5: "联系我们"
}
// 疾控中心
export const item3 = {
    title: "疾控中心温湿度监测系统解决方案",
    maxImg: require("@/assets/images/mPlan-scene/mPlan-scene_5.jpg"),
    p1: "行业背景",
    p1TextList:[
        "《中华人民共和国疫苗管理法》实施,明确要求建设完善疫苗追溯监管体系,对疫苗研制、生产、流通、预防接种全过程提出更加严格的要求。目前的疫苗冷链管理没有统一化的冷链监测管理平台,不能够及时发现问题,而且效率低下。各级疾控机构的监测设备也存在参差不齐,数据存储多样化等问题,需要提高信息化程度来完善风险预防体系,并建立有效的监管手段。为了帮助疾控机构建设完善的风险防范体系,提高监管效率,统一监测标准,实现疫苗质量安全全程可追溯,我司推出疾控机构温湿度监测方案,实现冷链全程质量监控,助力疫苗安全。",
    ],
    p2List:[
        {p2:'适用范围',p2Text: "疫苗冷库、疫苗冷柜、疫苗冷藏车、疫苗保温箱等",}
    ],
    p2Img: [
        { id: 1, name: "疫苗冷库", imgSrc: require("@/assets/images/mPlan-scene/mPlan-scene_3.jpg"), },
        { id: 2, name: "疫苗冷柜", imgSrc: require("@/assets/images/mPlan-scene/mPlan-scene_2.jpg"), },
        { id: 3, name: "疫苗冷藏车", imgSrc: require("@/assets/images/mPlan-scene/mPlan-scene_4.jpg"), },
        { id: 4, name: "疫苗保温箱", imgSrc: require("@/assets/images/mPlan-scene/mPlan-scene_1.jpg"), },
    ],

    p3: "系统特点",
    p3H: "中科物联温湿度自动监测系统功能特点",
    p3Text: [
        "集团公司总部可对分公司、分仓或门店的仓库、冷柜、保温箱、冷藏车运输过程中的温湿度数据和设备运行状况,随时进行异地监测和统一管理；",
        "全国各地仓库、冷柜、冷藏车、保温箱数据统一上传至云端存储,便于管理和查看;",
        "支持多路温湿度采集,可同时监测多个位置数据;",
        "自动监测温湿度数据,具有短信、微信、电话和现场报警功能;",
        "对开关门支持秒级监测,精确控制开关门操作规范;",
        "可实时显示设备的温湿度数据,可随时查看数据变化曲线、历史数据、超限数据等;",
        "支持手机、电脑、Pad多端同时在线监管,随时随地查看数据；",
        "监测数据支持断点续传功能,可自动补传数据;",
        "支持机载打印、蓝牙打印、外接连线打印多种方式;",
        "双路供电,大容量电池保证续航能力;",
        "数据存放安全,支持异地自动灾备;",
    ],

    p4: "系统拓扑图",
    p4Text: [
        { id: 1, name: "疫苗冷库监测", imgSrc: require("@/assets/images/mpItem/mpItem_3.jpg"), },
        { id: 2, name: "疫苗冷柜监测", imgSrc: require("@/assets/images/mpItem/mpItem_2.jpg"), },
        { id: 3, name: "疫苗冷藏车监测", imgSrc: require("@/assets/images/mpItem/mpItem_5.jpg"), },
        { id: 4, name: "疫苗保温箱监测", imgSrc: require("@/assets/images/mpItem/mpItem_1.jpg"), },
    ],

    p5: "联系我们"
}
// 物流冷链
export const item4 = {
    title: "物流行业温湿度监测系统解决方案",
    maxImg: require("@/assets/images/mPlan-scene/mPlan-scene_6.jpg"),
    p1: "行业背景",
    p1TextList:[
        "近年来随着我国经济快速发展,人们生活需求日益增长,对物质的需求也在快速提升,物流行业也在持续稳步发展,人们已经可以品尝到来自世界各地的生鲜、蔬菜、短保食品等。我司帮助仓储物流行业搭建一整套安全、稳定、智能的温湿度监测系统,为仓储和运输货物保驾护航。",
    ],
    p2List:[
        {p2:'适用范围',p2Text: "仓库/冷库、冷柜、冷藏车、保温箱等",}
    ],
    p2Img: [
        { id: 1, name: "仓库/冷库", imgSrc: require("@/assets/images/mPlan-scene/mPlan-scene_3.jpg"), },
        { id: 2, name: "冷柜", imgSrc: require("@/assets/images/mPlan-scene/mPlan-scene_2.jpg"), },
        { id: 3, name: "冷藏车", imgSrc: require("@/assets/images/mPlan-scene/mPlan-scene_4.jpg"), },
        { id: 4, name: "保温箱", imgSrc: require("@/assets/images/mPlan-scene/mPlan-scene_1.jpg"), },
    ],

    p3: "系统特点",
    p3H: "中科物联温湿度自动监测系统功能特点",
    p3Text: [
        "集团公司总部可对分公司、分仓或门店的仓库、冷柜、保温箱、冷藏车运输过程中的温湿度数据和设备运行状况,随时进行异地监测和统一管理；",
        "全国各地仓库、冷柜、冷藏车、保温箱数据统一上传至云端存储,便于管理和查看;",
        "支持多路温湿度采集,可同时监测多个位置数据;",
        "自动监测温湿度数据,具有短信、微信、电话和现场报警功能;",
        "对开关门支持秒级监测,精确控制开关门操作规范;",
        "可实时显示设备的温湿度数据,可随时查看数据变化曲线、历史数据、超限数据等;",
        "支持手机、电脑、Pad多端同时在线监管,随时随地查看数据；",
        "监测数据支持断点续传功能,可自动补传数据;",
        "支持机载打印、蓝牙打印、外接连线打印多种方式;",
        "双路供电,大容量电池保证续航能力;",
        "数据存放安全,支持异地自动灾备;",
    ],

    p4: "系统拓扑图",
    p4Text: [
        { id: 1, name: "仓库/冷库监测", imgSrc: require("@/assets/images/mpItem/mpItem_3.jpg"), },
        { id: 2, name: "冷柜监测", imgSrc: require("@/assets/images/mpItem/mpItem_2.jpg"), },
        { id: 3, name: "冷藏车监测", imgSrc: require("@/assets/images/mpItem/mpItem_5.jpg"), },
        { id: 4, name: "保温箱监测", imgSrc: require("@/assets/images/mpItem/mpItem_1.jpg"), },
    ],

    p5: "联系我们"
}
// 仓库监测
export const item5 = {
    title: "冷库温湿度监测解决方案",
    maxImg: require("@/assets/images/mPlan-trade/mPlan-trade_3.jpg"),
    p1: "系统背景",
    p1TextList:[
        "根据国家《药品经营质量管理规范(2016 年修订)》及相关附录(2013 年修订),冷库需要安装温湿度检测系统。确保冷库能够满足药品冷藏储存的要求,有效防范储存过程中可能发生的影响药品质量安全的风险,确保药品质量安全。",
    ],
    p2List:[
        {p2:'适用范围',p2Text: "医药行业、医疗机构、疾控中心、物流冷链等",}
    ],
    p2Img: [
        { id: 1, name: "医药行业", imgSrc: require("@/assets/images/mPlan-trade/mPlan-trade_8.jpg"), },
        { id: 2, name: "医疗机构", imgSrc: require("@/assets/images/mPlan-trade/mPlan-trade_7.jpg"), },
        { id: 3, name: "疾控中心", imgSrc: require("@/assets/images/mPlan-trade/mPlan-trade_1.jpg"), },
        { id: 4, name: "物流冷链", imgSrc: require("@/assets/images/mPlan-trade/mPlan-trade_6.jpg"), },
    ],

    p3: "系统特点",
    p3H: "中科物联温湿度自动监测系统功能特点",
    p3Text: [
        "集团公司总部可对分公司、分仓或门店的仓库、冷柜、保温箱、冷藏车运输过程中的温湿度数据和设备运行状况,随时进行异地监测和统一管理；",
        "全国各地仓库、冷柜、冷藏车、保温箱数据统一上传至云端存储,便于管理和查看;",
        "支持多路温湿度采集,可同时监测多个位置数据;",
        "自动监测温湿度数据,具有短信、微信、电话和现场报警功能;",
        "对开关门支持秒级监测,精确控制开关门操作规范;",
        "可实时显示设备的温湿度数据,可随时查看数据变化曲线、历史数据、超限数据等;",
        "支持手机、电脑、Pad多端同时在线监管,随时随地查看数据；",
        "监测数据支持断点续传功能,可自动补传数据;",
        "支持机载打印、蓝牙打印、外接连线打印多种方式;",
        "双路供电,大容量电池保证续航能力;",
        "数据存放安全,支持异地自动灾备;",
    ],

    p4: "系统拓扑图",
    p4Text: [
        { id: 1, name: "无线/有线监测", imgSrc: require("@/assets/images/mpItem/mpItem_3.jpg"), },
    ],

    p5: "联系我们"
}
// 冷柜监测
export const item6 = {
    title: "冷柜温湿度监测解决方案",
    maxImg: require("@/assets/images/mPlan-trade/mPlan-trade_5.jpg"),
    p1: "系统背景",
    p1TextList:[
        "医用冷柜是保存药物、疫苗、干细胞、血液存储、组织样本和一些重要的生物和化学试剂等特殊药品的专业冷藏柜,具有数显温度控制系统,依据GSP标准温度调节范围一般是 2~8C。除医用以外,对温度敏感的食品也必须要在冷藏环境中储存,使用的冰箱冷柜是否在设定的温度状态下运作,直接影响产品的质量。",
    ],
    p2List:[
        {p2:'适用范围',p2Text: "医药行业、医疗机构、疾控中心、物流冷链等",}
    ],
    p2Img: [
        { id: 1, name: "医药行业", imgSrc: require("@/assets/images/mPlan-trade/mPlan-trade_8.jpg"), },
        { id: 2, name: "医疗机构", imgSrc: require("@/assets/images/mPlan-trade/mPlan-trade_7.jpg"), },
        { id: 3, name: "疾控中心", imgSrc: require("@/assets/images/mPlan-trade/mPlan-trade_1.jpg"), },
        { id: 4, name: "物流冷链", imgSrc: require("@/assets/images/mPlan-trade/mPlan-trade_6.jpg"), },
    ],

    p3: "系统特点",
    p3H: "中科物联温湿度自动监测系统功能特点",
    p3Text: [
        "集团公司总部可对分公司、分仓或门店的仓库、冷柜、保温箱、冷藏车运输过程中的温湿度数据和设备运行状况,随时进行异地监测和统一管理；",
        "全国各地仓库、冷柜、冷藏车、保温箱数据统一上传至云端存储,便于管理和查看;",
        "支持多路温湿度采集,可同时监测多个位置数据;",
        "自动监测温湿度数据,具有短信、微信、电话和现场报警功能;",
        "对开关门支持秒级监测,精确控制开关门操作规范;",
        "可实时显示设备的温湿度数据,可随时查看数据变化曲线、历史数据、超限数据等;",
        "支持手机、电脑、Pad多端同时在线监管,随时随地查看数据；",
        "监测数据支持断点续传功能,可自动补传数据;",
        "支持机载打印、蓝牙打印、外接连线打印多种方式;",
        "双路供电,大容量电池保证续航能力;",
        "数据存放安全,支持异地自动灾备;",
    ],

    p4: "系统拓扑图",
    p4Text: [
        { id: 1, name: "集中/零散摆放使用方式", imgSrc: require("@/assets/images/mpItem/mpItem_2.jpg"), },
    ],

    p5: "联系我们"
}
// 保温箱监测
export const item7 = {
    title: "保温箱温湿度监测解决方案",
    maxImg: require("@/assets/images/mPlan-trade/mPlan-trade_2.jpg"),
    p1: "系统背景",
    p1TextList:[
        "保温箱主要用作中途周转运输还有终端运输,其特点是耐热耐冷、密封性好、可保鲜、坚固耐用、多功能多用途。依据GSP规定运输冷藏、冷冻药品的冷藏车及车载冷藏箱、保温箱应当符合运输过程中对温度控制的要求。冷藏箱及保温箱具有外部显示和采集箱体内温度数据的功能。在冷藏、冷冻药品到货时,应当对其运输方式及运输过程的温度记录、运输时间等质量控制状况进行重点检查并记录。不符合温度要求的应当拒收",
    ],
    p2List:[
        {p2:'适用范围',p2Text: "医药行业、医疗机构、疾控中心、物流冷链等",}
    ],
    p2Img: [
        { id: 1, name: "医药行业", imgSrc: require("@/assets/images/mPlan-trade/mPlan-trade_8.jpg"), },
        { id: 2, name: "医疗机构", imgSrc: require("@/assets/images/mPlan-trade/mPlan-trade_7.jpg"), },
        { id: 3, name: "疾控中心", imgSrc: require("@/assets/images/mPlan-trade/mPlan-trade_1.jpg"), },
        { id: 4, name: "物流冷链", imgSrc: require("@/assets/images/mPlan-trade/mPlan-trade_6.jpg"), },
    ],

    p3: "系统特点",
    p3H: "中科物联温湿度自动监测系统功能特点",
    p3Text: [
        "集团公司总部可对分公司、分仓或门店的仓库、冷柜、保温箱、冷藏车运输过程中的温湿度数据和设备运行状况,随时进行异地监测和统一管理；",
        "全国各地仓库、冷柜、冷藏车、保温箱数据统一上传至云端存储,便于管理和查看;",
        "支持多路温湿度采集,可同时监测多个位置数据;",
        "自动监测温湿度数据,具有短信、微信、电话和现场报警功能;",
        "对开关门支持秒级监测,精确控制开关门操作规范;",
        "可实时显示设备的温湿度数据,可随时查看数据变化曲线、历史数据、超限数据等;",
        "支持手机、电脑、Pad多端同时在线监管,随时随地查看数据；",
        "监测数据支持断点续传功能,可自动补传数据;",
        "支持机载打印、蓝牙打印、外接连线打印多种方式;",
        "双路供电,大容量电池保证续航能力;",
        "数据存放安全,支持异地自动灾备;",
    ],

    p4: "系统拓扑图",
    p4Text: [
        { id: 1, name: "独立使用/配合车载采集器使用方式", imgSrc: require("@/assets/images/mpItem/mpItem_1.jpg"), },
    ],

    p5: "联系我们"
}
// 冷藏车监测
export const item8 = {
    title: "冷藏车温湿度监测解决方案",
    maxImg: require("@/assets/images/mPlan-trade/mPlan-trade_4.jpg"),
    p1: "系统背景",
    p1TextList:[
        "冷藏车是用来运输冷冻或保鲜货物的封闭式厢式运输车,是装有制冷机组的制冷装置和聚氨醋隔热厢的冷藏专用运输汽车。冷藏车常用于运输疫苗药品、冷冻食品、奶类制品、水果生鲜等。依据GSP规定运输冷藏、冷冻药品的冷藏车及车载冷藏箱、保温箱应当符合运输过程中对温度控制的要求。冷藏车具有自动调控温度、显示温度、存储和读取温度监测数据的功能;冷藏箱及保温箱具有外部显示和采集箱体内温度数据的功能。冷藏、冷冻药品到货时,应当对其运输方式及运输过程的温度记录、运输时间等质量控制状况进行重点检查并记录。不符合温度要求的应当拒收。在冷藏、冷冻药品运输途中,应当实时监测并记录冷藏车、冷藏箱或者保温箱内的温度数据",
    ],
    p2List:[
        {p2:'适用范围',p2Text: "医药行业、医疗机构、疾控中心、物流冷链等",}
    ],
    p2Img: [
        { id: 1, name: "医药行业", imgSrc: require("@/assets/images/mPlan-trade/mPlan-trade_8.jpg"), },
        { id: 2, name: "医疗机构", imgSrc: require("@/assets/images/mPlan-trade/mPlan-trade_7.jpg"), },
        { id: 3, name: "疾控中心", imgSrc: require("@/assets/images/mPlan-trade/mPlan-trade_1.jpg"), },
        { id: 4, name: "物流冷链", imgSrc: require("@/assets/images/mPlan-trade/mPlan-trade_6.jpg"), },
    ],

    p3: "系统特点",
    p3H: "中科物联温湿度自动监测系统功能特点",
    p3Text: [
        "集团公司总部可对分公司、分仓或门店的仓库、冷柜、保温箱、冷藏车运输过程中的温湿度数据和设备运行状况,随时进行异地监测和统一管理；",
        "全国各地仓库、冷柜、冷藏车、保温箱数据统一上传至云端存储,便于管理和查看;",
        "支持多路温湿度采集,可同时监测多个位置数据;",
        "自动监测温湿度数据,具有短信、微信、电话和现场报警功能;",
        "对开关门支持秒级监测,精确控制开关门操作规范;",
        "可实时显示设备的温湿度数据,可随时查看数据变化曲线、历史数据、超限数据等;",
        "支持手机、电脑、Pad多端同时在线监管,随时随地查看数据；",
        "监测数据支持断点续传功能,可自动补传数据;",
        "支持机载打印、蓝牙打印、外接连线打印多种方式;",
        "双路供电,大容量电池保证续航能力;",
        "数据存放安全,支持异地自动灾备;",
    ],

    p4: "系统拓扑图",
    p4Text: [
        { id: 1, name: "4G数据通讯", imgSrc: require("@/assets/images/mpItem/mpItem_5.jpg"), },
    ],

    p5: "联系我们"
}
// 海鲜水产
export const item9 = {
    title: "海鲜水产",
    maxImg: '',
    maxImg2:'',
    p1: "",
    p1TextList:[
        "随着我国民众生活水平的提高,对于海鲜、水产品保障供给要求也逐步提升,其中一个最为重要的目标是提高海鲜、水产品的质量,因此对海鲜、水产品运输和保鲜的要求也越来越高。除传统的对水产品冷链降温类型外,对于鲜活水产品的需求愈发旺盛。鲜活水产品在运输过程中对于水温有明确的区间,部分品类需要密切关注水溶氧以及二氧化碳等气体的浓度,北京中科希望物联网络科技提供了实时监控多种鲜活水产品运输的有效监控方案,对减少货损,增加海鲜水产品质量具有重要意义。",
    ],
    maxImgList: [
        //imgStyle:true,styles:'width:1000px'
        {id:0,name:'',url:require("@/assets/images/mpItem/mp9/1.png")}
    ],
    p2List:[
        {p2: "海鲜水产品温湿度监测系统功能",p2Text: "北京中科希望物联网络科技有限公司的海鲜水产品温湿度监测系统方案,可用于海鲜、水产品温湿度、水溶氧及二氧化碳等各项参数远(近）程监测,并将数据传输到平台,进行存储和数据分析。当监测的某项参数出线异常,系统会进行本地的声光报警及平台下发的短信、电话、微信同时下发报警信息,实现无人值守的远程监控。"},
        {p2: "海鲜水产品温湿度监测系统适用范围",p2Text: "海鲜水产品养殖基地、中转仓库、海鲜水产品运输车辆等"}
    ],
    p2Img: [
        { id: 1, name: "海鲜水产品养殖基地", imgSrc: require("@/assets/images/mpItem/mp9/2.png"), },
        { id: 2, name: "中转仓库", imgSrc: require("@/assets/images/mpItem/mp9/3.png"), },
        { id: 3, name: "海鲜水产品运输车辆", imgSrc: require("@/assets/images/mpItem/mp9/4.png"), },
    ],
    p3: "",
    p3H: "",
    p3Text: [],
    p4: "中科物联药品零售企业温湿度监控方案",
    p4Text: [
        {id: 1,name: "海鲜水产品养殖基地、中转仓库：",imgSrc: require("@/assets/images/mpItem/mp9/5.png"), },
        {id: 2,name: "冷藏运输车：",imgSrc: require("@/assets/images/mpItem/mp9/6.png"),imgStyle:false ,styles:{cur:''}},
    ],

    p5: "联系我们"
}
// 低温速冻食品
export const item10 = {
    title: "低温、速冻类食品",
    maxImg: '',
    maxImg2:'',
    p1: "",
    p1TextList:[
        "2024年1月5日,中轻食品工业管理中心就《速冻调制食品》行业标准公开征求意见,截止日期为2024年1月30日。《速冻调制食品》(征求意见稿）拟代替SB/T 10379《速冻调制食品》。",
    ],
    maxImgList: [],
    p2List:[
        {p2:'SB/T 10379   3.1 ',p2Text:"以谷物或豆类或薯类及其制品、畜禽肉及其制品、水产品及其制品、植物蛋白及其制品、果蔬及其制品、蛋及其制品、食用菌及其制品等为主要原料,配以辅料(含食品添加剂）,经调味制作加工,采用速冻工艺(产品热中心温度≤-18℃）,在低温状态下贮存、运输和营销的预包装食品。"},
        {p2: "速冻食品温湿度监测系统适用范围",p2Text: "速冻食品加工厂、低温仓库、低温食品运输车、低温食品销售柜"},
    ],
    p2Img: [
        { id: 1, name: "速冻食品加工厂", imgSrc: require("@/assets/images/mpItem/mp10/1.jpg"), },
        { id: 2, name: "低温仓库", imgSrc: require("@/assets/images/mpItem/mp10/2.jpg"), },
        { id: 3, name: "低温食品运输车", imgSrc: require("@/assets/images/mpItem/mp10/3.jpg"), },
        { id: 4, name: "低温食品销售柜", imgSrc: require("@/assets/images/mpItem/mp10/4.jpg"), },
    ],
    p3: "",
    p3H: "",
    p3Text: [],
    p4: "中科物联药品零售企业温湿度监控方案",
    p4Text: [
        {id: 1,name: "海鲜水产品养殖基地、中转仓库：",imgSrc: require("@/assets/images/mpItem/mp10/5.png"), },
        {id: 2,name: "冷藏运输车：",imgSrc: require("@/assets/images/mpItem/mp10/6.png"),imgStyle:false ,styles:{cur:''}},
        {id: 2,name: "低温食品销售柜：",imgSrc: require("@/assets/images/mpItem/mp10/7.png"),imgStyle:false ,styles:{cur:''}},
        
    ],

    p5: "联系我们"
}
// 肉蛋水果蔬菜
export const item11 = {
    title: "果蔬肉蛋",
    maxImg: require("@/assets/images/mpItem/mp11/1.jpg"),
    maxImg2:'',
    p1: "瓜果蔬菜如何保持新鲜？",
    p1TextList:[
        "叶类蔬菜水分较多,所以保鲜主要是要保持水分,需要放进冰箱冷藏室或0℃保鲜层,湿度控制在80%~95%之间,当然不同种类的蔬菜温湿度会略有不同。",
    ],
    maxImgList: [],
    p2List:[
        {p2:'生鲜肉类',imgSrc:require("@/assets/images/mpItem/mp11/2.jpg"),p2Text:"鲜肉的最佳保鲜温度是3～5℃,在这个温度下口感和营养都是最好的,如果需要储存超过24小时,建议放入-5℃以下的冷冻室。要是打算1周后才食用,就一定要为冷冻室设置,-10℃～-18℃温度进行速冻。"},
        {p2: "蛋类保鲜",imgSrc:require("@/assets/images/mpItem/mp11/3.jpg"),p2Text: "蛋类在常温下难以储存,特别是夏季,少量可以放在中,大量鸡蛋就得放在保鲜冷库储存,冷库储存适宜温度为0℃~1℃,库内相对湿度85%~90%,存储时间为8-12个月。"},
        {p2:'中科物联温湿度监测系统功能',p2Text:'北京中科希望物联网络科技有限公司的温湿度监测系统方案,可用于果蔬蛋类存储、冷冻库保存、冷藏车运输以及超市售卖所有环节各项数据远(近）程监测,并将数据传输到平台,进行存储和数据分析。当监测的某项参数出线异常,系统会进行本地的声光报警及平台下发的短信、电话、微信同时下发报警信息,实现无人值守的远程监控。'},
        {p2:'果蔬肉蛋温湿度监测系统适用范围'}
    ],
    p2Img: [
        { id: 1, name: "保鲜冷冻仓库", imgSrc: require("@/assets/images/mpItem/mp11/4.jpg"), },
        { id: 2, name: "冷藏运输车", imgSrc: require("@/assets/images/mpItem/mp11/5.jpg"), },
        { id: 3, name: "超市销售货架", imgSrc: require("@/assets/images/mpItem/mp11/6.jpg"), },
    ],
    p3: "",
    p3H: "",
    p3Text: [],
    p4: "中科物联药品零售企业温湿度监控方案",
    p4Text: [
        {id: 1,name: "保鲜冷冻仓库：",imgSrc: require("@/assets/images/mpItem/mp11/7.png"), },
        {id: 2,name: "冷藏运输车：",imgSrc: require("@/assets/images/mpItem/mp11/8.jpg"),imgStyle:false ,styles:{cur:''}},
        {id: 2,name: "超市货架：",imgSrc: require("@/assets/images/mpItem/mp11/9.png"),imgStyle:false ,styles:{cur:''}},
        
    ],

    p5: "联系我们"
}
// 肉蛋水果蔬菜
export const item12 = {
    title: "预制菜",
    maxImg: '',
    maxImg2:'',
    p1: "",
    p1TextList:[],
    maxImgList: [
        {id:0,name:'',url:require("@/assets/images/mpItem/mp12/1.png"),
        imgStyle:true,styles:'width:1000px'},
        {id:0,name:'',url:require("@/assets/images/mpItem/mp12/2.png"),
        imgStyle:true,styles:'width:1000px'}
    ],
    p2List:[
        {p2:'首次在国家层面明确预制菜定义',p2Text:"3月21日,国家市场监督管理总局发布预制菜相关通知,通知明确规定,不得使用防腐剂,同时,预制菜要通过冷冻、冷藏等贮存条件和杀菌后处理工艺,那么在不使用防腐剂的情况下,预制菜如何全程保持冷冻低温状态,必然将成为关乎人民身体健康甚至是生命安全的重中之重。"},
        {p2:"预制菜温湿度监测系统功能",p2Text: "北京中科希望物联网络科技有限公司的温湿度监测系统方案,可用于预制菜的原材料存储、工厂生产加工车间、冷冻库保存、冷藏车运输以及超市售卖所有环节各项数据远(近）程监测,并将数据传输到平台,进行存储和数据分析。当监测的某项参数出线异常,系统会进行本地的声光报警及平台下发的短信、电话、微信同时下发报警信息,实现无人值守的远程监控。确保每一份食品都符合低温存储,让顾客吃的放心。"},
        {p2:'预制菜温湿度监测系统适用范围'}
    ],
    p2Img: [
        { id: 1, name: "原材料仓库", imgSrc: require("@/assets/images/mpItem/mp12/3.jpg"), },
        { id: 2, name: "生产加工车间", imgSrc: require("@/assets/images/mpItem/mp12/4.png"), },
        { id: 3, name: "成品冷冻库", imgSrc: require("@/assets/images/mpItem/mp12/5.png"), },
        { id: 4, name: "冷藏运输车", imgSrc: require("@/assets/images/mpItem/mp12/6.jpg"), },
        { id: 5, name: "超市销售货架", imgSrc: require("@/assets/images/mpItem/mp12/7.jpg"), },
    ],
    p3: "",
    p3H: "",
    p3Text: [],
    p4: "中科物联药品零售企业温湿度监控方案",
    p4Text: [
        {id: 1,name: "原材料、成品仓库:",imgSrc: require("@/assets/images/mpItem/mp12/8.png"), },
        {id: 2,name: "冷藏运输车:",imgSrc: require("@/assets/images/mpItem/mp12/9.jpg"),imgStyle:false ,styles:{cur:''}},
        {id: 2,name: "超市货架:",imgSrc: require("@/assets/images/mpItem/mp12/10.png"),imgStyle:false ,styles:{cur:''}},
        
    ],

    p5: "联系我们"
}
// 仓储物流
export const item13 = {
    title: "仓储物流",
    maxImg: '',
    maxImg2:'',
    p1: "",
    p1TextList:[
        '在当今全球化的商业环境中,物流作为连接生产与消费的关键环节,其效率和质量直接影响着企业的竞争力和消费者的满意度。其中,货物在运输过程中的温湿度控制是确保货物质量、安全等关键因素之一。因此,物流温湿度监控系统的应用显得尤为重要。'
    ],
    maxImgList: [
        {id:0,name:'',url:require("@/assets/images/mpItem/mp13/1.jpg"),
        imgStyle:true,styles:'width:1000px'},
    ],
    p2List:[
        {p2:'物流温湿度监测系统',p2Text:"物流温湿度监控系统是一种集成了传感器技术、无线通信技术、数据处理和分析技术的智能化管理系统。实时监测与预警,在货物运输过程中时刻监测运输过程中的温湿度变化,并在温湿度超出预设范围时立即发出预警信号。"},
        {p2:'仓储物流温湿度监测系统适用范围'}
    ],
    p2Img: [
        { id: 1, name: "低温医药类货物运输", imgSrc: require("@/assets/images/mpItem/mp13/2.png"), },
        { id: 2, name: "冷冻类食品运输", imgSrc: require("@/assets/images/mpItem/mp13/3.png"), },
        { id: 3, name: "冷链仓储", imgSrc: require("@/assets/images/mpItem/mp13/4.png"), },
    ],
    p3: "",
    p3H: "",
    p3Text: [],
    p4: "中科物联仓储物流温湿度监控方案",
    p4Text: [
        {id: 1,name: "物流运输车:",imgSrc: require("@/assets/images/mpItem/mp13/5.jpg")},
        {id: 2,name: "物流仓储:",imgSrc: require("@/assets/images/mpItem/mp13/6.png")},
        
    ],
    p5: "联系我们"
}
// 半导体存储
export const item14 = {
    title: "半导体存储",
    maxImg: '',
    maxImg2:'',
    p1: "",
    p1TextList:[
        '据统计,全球每年有1/4以上的工业制造不良品与潮湿的危害有关。对于电子工业,潮湿的危害已经成为影响产品质量的主要因素之一。'
    ],
    maxImgList: [
        {id:0,name:'',url:require("@/assets/images/mpItem/mp14/1.jpg"),
        imgStyle:true,styles:'width:1000px'},
    ],
    
    p2List:[
        {p2:'不同类型半导体器件需要的温湿度',imgSrc:require("@/assets/images/mpItem/mp14/2.jpg"),imgStyle:true,styles:'width:1000px',p2Text:"集成电路：潮湿对半导体产业的危害主要表示在潮湿能透过IC塑料封装从引脚等缝隙侵入IC内部,发生IC吸湿现象。SMT过程的加热环节中形成水蒸气,发生的压力导致IC树脂封装开裂,并使IC器件内部金属氧化,导致产品故障。此外,当器件在PCB板的焊接过程中,因水蒸气压力的释放,亦会导致虚焊。"},
        {p2:'',imgSrc:require("@/assets/images/mpItem/mp14/3.jpg"),imgStyle:true,styles:'width:400px;height:400px',p2Text:'液晶器件：液晶显示屏等液晶器件的玻璃基板和偏光片、滤镜片在生产过程中虽然要进行清洗烘干,但待其降温后仍然会受潮气的影响,降低产品的合格率。因此在清洗烘干后应存放于40%RH以下的干燥环境中。'},
        {p2:'',imgSrc:require("@/assets/images/mpItem/mp14/4.jpg"),imgStyle:false,p2Text:'另外像电感、二极管、晶体管等一般存放温度要求为-55℃到+125℃之间,湿度40%~70%,湿度过大可能会导致磁芯生锈,湿度过小则会引起电感器电性能变差,电容器电性能变差等。'}
    ],
    p2Img: [],
    p3: "如何确保半导体器件仓库始终处于正确的温湿度范围？",
    p3H: "",
    p3Text: [],
    p3Text1: ['中科物联十年沉淀,匠心打造温湿度自动监控平台,集团化管理,全程数据监测,多层温湿度风险预警、报警防控,超温超湿后可通过短信、电话、微信公众号三种方式进行通知。仓库进行科学验证后,选择准确的监测点位,实时确保您的资产安全,详细设备配置可咨询中科售前工程师。'],
    p4: "",
    p4Text: [
        {id: 1,name: "",imgSrc: require("@/assets/images/mpItem/mp14/5.png")},        
    ],
    p5: "联系我们"
}
// 实验室
export const item15 = {
    title: "实验室",
    maxImg: '',
    maxImg2:'',
    p1: "",
    p1TextList:[
        '据统计,全球每年有1/4以上的工业制造不良品与潮湿的危害有关。对于电子工业,潮湿的危害已经成为为了降低试验误差、确保实验结果的真实性和准确性,实验室的温度、湿度、压差、光照等环境参数不容忽视。',
        '在ISO 9001、GxP、GMP、GLP等标准、准则和法规中,规定了不同实验室,依据其功能性和放置的设备和环境要求。'
    ],
    maxImgList: [
        {id:0,name:'',url:require("@/assets/images/mpItem/mp15/1.jpg"),
        imgStyle:true,styles:'width:1000px'},
    ],
    
    p2List:[],
    p2Img: [],
    p3: "常见的温湿度监测方式",
    p3H: "",
    p3Text: [],
    p3Text1: ['数字式温湿度监测,需要手动归档,没有空气湿度和温度变化曲线图。',
	'数据记录仪,自动记录测量数据,手动读取,数据记录仪损坏时,有丢失的风险。',
	'温湿度监测系统,连续自动记录温度和空气湿度测量曲线,可设置多个报警临界值,超温超湿时可通过短信、电话、微信多种方式进行报警,数据存储云端,可通过手机实时观测曲线图。'],
    p4: "中科希望温湿度监测系统",
    p4Text: [
        {id: 1,name: "用户可以根据测量需求、预算等自主选择适合自己的监测解决方案。除了不间断地记录和归档环境数据外,自动化监测系统还适用于可靠地监测实验室和各种冷藏设备的温度和湿度情况,甚至可以用于零下一百摄氏度极端环境下的温度监测。",imgSrc: require("@/assets/images/mpItem/mp15/2.jpg"),textStyle:'text-indent: 2em;font-weight: 400'},
        {id:2,name:'中科TW-401LW-4G-2物联网温湿度记录仪本身自带4G模块和WiFi模块,设备可以根据是否插入数据卡选择联网方式,插卡的情况下会连接4G网络,未插卡的情况连接WiFi网络,可实现温湿度数据的实时上传,使用时将设备固定在冰箱外侧,探头通过延长线深入冰箱内部进行测量。自带1000毫安时电池,断电后续航时间12小时。电脑、收集APP实时观看温湿度数据。',textStyle:'text-indent: 2em;font-weight: 400'}        
    ],
    p5: "联系我们"
}
// 化学危险品
export const item16 = {
    title:'化学品危险品',
    maxImg:require("@/assets/images/mpItem/mp16/1.jpg"),
    maxImg2:'',
    p1:'',
    p1TextList:['危化品储存过程的监测和管理尤为重要,通过对存储环境的温湿度情况进行实时自动在线监测, 为危化品仓储提供安全保障,避免安全事故发生。通过现代化的技术手段,基于物联网技术构建完全 自主的自动化监测系统,实现对危化品仓储环境的24小时实时监测、实时报警(预警、报警）、多级报警(微信、短信、电话）、数据分析、数据追溯等功能服务。'],
    maxImgList:[
      {url:require("@/assets/images/mpItem/mp16/2.png"),imgStyle:false,styles:''}
    ],
    p2List:[
      // {id:0,p2:'',imgSrc:'',imgStyle:'',styles:'',p2Text:''}
    ],
    p2Img:[
      // {id:0,imgSrc:'',name:''}
    ],
    p3:'化学危险品温湿度监测系统',
    p3H:'',
    p3Text:[],
    p3Text1:['中科温湿度监控系统是一种集成了传感器技术、无线通信技术、数据处理和分析技术的智能化管理系统。实时监测与预警,在货物存储和运输过程中时刻监测温湿度变化,并在温湿度超出预设范围时立即发出预警信号。'],
    p4:'中科物联化学危险品温湿度监控方案',
    p4Text:[
      {id:0,name:'危险品运输:',imgSrc:require("@/assets/images/mpItem/mp16/3.png"),imgStyle:false,
      styles:{parent:'',cur:''},},
      {id:1,name:'危险品存储:',imgSrc:require("@/assets/images/mpItem/mp16/4.png"),imgStyle:false,
      styles:{parent:'',cur:''},}
    ]
  }
  // 粮食存储
  export const item17 = {
    title:'粮食存储',
    maxImg:require("@/assets/images/mpItem/mp17/1.jpg"),
    maxImg2:'',
    p1:'',
    p1TextList:['中国是粮食消费大国,2023年全国粮食总产量13908亿斤,比2022年增加177.6亿斤,增长1.3%。据不完全资料显示,除国家收购的储备粮食以外,我国有60%~70%以上的粮食储存在农户家里,而因农户储藏方式不当导致粮食损失约占存粮总量的8%~10%。这不仅仅影响到农民的生产收入,更威胁到了我国粮食储备的安全。'],
    maxImgList:[
      // {url:'',imgStyle:false,styles:''}
    ],
    p2List:[
      {id:0,p2:'粮仓管理',imgSrc:require("@/assets/images/mpItem/mp17/2.jpg"),imgStyle:'',styles:'',p2Text:'粮仓管理的重点之一在于合理的布置测温点,因为粮仓需要经常检查温度变化,方便及时发现粮仓的发热点,及时减少粮食的损失。然而,粮堆的热传递又较为缓慢,使人感知极差,需要管理人员经常进入闷热且呛人的粮仓内检测粮堆的温湿度,不断进行翻仓、通风这种繁重的体力劳动,不仅对人体有极大的伤害,而且不科学、不及时,所以粮食虫蛀、霉变的情况常有发生。对此,中科物联推出粮仓温湿度监测解决方案,用来解决此类问题。'}
    ],
    p2Img:[
    //   {id:0,imgSrc:'',name:''}
    ],
    p3:'',
    p3H:'',
    p3Text:[],
    p3Text1:[''],
    p4:'中科物联粮仓温湿度监控方案',
    p4Text:[
      {id:0,name:'',imgSrc:require("@/assets/images/mpItem/mp17/3.png"),imgStyle:false,
      styles:{parent:'',cur:''},}
    ]
  }
  // 温室大棚
  export const item18 = {
    title:'温室大棚',
    maxImg:'',
    maxImg2:'',
    p1:'',
    p1TextList:['智能温室监测系统是根据无线网络获取植物实时生长环境信息,通过各个类型传感器可以检测土壤水分、土壤温度、空气温度、空气湿度等参数,信息收集负责接收无线传感汇聚节点发来的数据、存储、显示和数据管理实现所有基地测点信息的获取、管理、动态显示和分析处理以直观的曲线方式显示给用户。'],
    maxImgList:[
      {url:require("@/assets/images/mpItem/mp18/1.png"),imgStyle:false,styles:''}
    ],
    p2List:[
      {id:0,p2:'智能温室监测系统',imgSrc:'',imgStyle:'',styles:'',p2Text:'智能温室监测系统是一种集成了传感器技术、无线通信技术、数据处理和分析技术的智能化管理系统。实时监测与预警,在植物生长整个过程中实时监测空气、土壤中的温湿度变化,并在温湿度超出预设范围时本地声光、异地微信、短信、电话报警通知。'}
    ],
    p2Img:[
      // {id:0,imgSrc:'',name:''}
    ],
    p3:'中科物联温室大棚温湿度监控方案',
    p3H:'',
    p3Text:[],
    p3Text1:[''],
    p4:'',
    p4Text:[
      {id:0,name:'',imgSrc:require("@/assets/images/mpItem/mp18/2.png"),imgStyle:false,
      styles:{parent:'',cur:''},}
    ]
  }
  // 烟草行业
  export const item19 = {
    title:'烟草行业',
    maxImg:require("@/assets/images/mpItem/mp19/1.jpg"),
    maxImg2:'',
    p1:'',
    p1TextList:['烟草这种特殊产品极易受到环境的影响,所以烟草的存储环境是非常重要的,一个好的烟草仓库要保证温湿度在一定的区间内。这样才能保证烟草的存储质量。如温湿度异常,可能导致烟草发霉、变质。在烟草仓库内,必须对温湿度进行严格的监测,定时将监测点的温湿度数据上传、打印温湿度曲线进行分析,消除一切的隐患。'],
    maxImgList:[
      // {url:'',imgStyle:false,styles:''}
    ],
    p2List:[
      {id:0,p2:'烟草生产车间温湿度监测系统',imgSrc:require("@/assets/images/mpItem/mp19/2.jpg"),imgStyle:true,styles:'width:1000px',p2Text:'烟草生产工艺中温湿度控制是一项十分重要的内容,温湿度的合理控制直接关系着产品的品质,烟草生产车间的温湿度控制也是通过空调系统来实现的。温湿度控制的首要任务就是保证车间内做工时的温湿度达到工艺生产的要求。中科物联自主研发的4G远程温湿度传感可以时候监测烟草加工过程中温湿度变化情况为加工工人提供数据依据,此外随着技术的发展,香烟加工大多数用上机械化,借助蜂窝云平台可以实现香烟制作过程中温湿度自动调控。同时,根据温湿度自动调控加工环境的温湿度能够有效降低能源的损耗,大大提高了经济效益。'},
      {id:1,p2:'烟草行业温湿度监测系统功能',p2Text:'北京中科希望物联网络科技有限公司的温湿度监测系统方案,可用于烟草原材料存储、工厂生产加工车间、运输等所有环节各项数据远（近）程监测,并将数据传输到平台,进行存储和数据分析。当监测的某项参数出线异常,系统会进行本地的声光报警及平台下发的短信、电话、微信同时下发报警信息。'}
    ],
    p2Img:[
      // {id:0,imgSrc:'',name:''}
    ],
    p3:'',
    p3H:'',
    p3Text:[],
    p3Text1:[''],
    p4:'中科物联药品零售企业温湿度监控方案',
    p4Text:[
      {id:0,name:'原材料、成品仓库:',imgSrc:require("@/assets/images/mpItem/mp19/3.png"),imgStyle:false,
      styles:{parent:'',cur:''},}
    ]
  }
// 冰淇凌
export const item20 = {
    title:'冰激凌',
    maxImg:require("@/assets/images/mpItem/mp20/1.png"),
    maxImg2:'',
    p1:'',
    p1TextList:['2023年我国现存冰淇淋相关企业2.88万家。多家市场调研机构分析认为,中国冰淇淋市场年产销规模已超1600亿元,行业营收普遍增长,当前我国冰淇淋市场规模稳居全球第一。然而,庞大的消费市场以及新品频出的冰淇淋赛道里,冷链零担配送难题待解。冰淇淋因含有奶油等成分,对温度极其敏感,保存环境一旦高于零下18℃,冰激凌就会产生形变影响终端销售。'],
    maxImgList:[
      // {url:'',imgStyle:false,styles:''}
    ],
    p2List:[
      {id:0,p2:'中科物联冰激凌温湿度监测系统',imgSrc:'',imgStyle:'',styles:'',p2Text:'北京中科希望物联网络科技有限公司的温湿度监测系统方案,可用于冰激凌的原材料存储、工厂生产加工车间、冷冻库保存、冷藏车运输以及超市售卖所有环节各项数据远（近）程监测,并将数据传输到平台,进行存储和数据分析。当监测的某项参数出线异常,系统会进行本地的声光报警及平台下发的短信、电话、微信同时下发报警信息,实现无人值守的远程监控。确保每一个环节都符合低温存储。'},
      {id:1,p2:'冰激凌温湿度监测系统适用范围'}
    ],
    p2Img:[
      {id:0,imgSrc:require("@/assets/images/mpItem/mp20/2.png"),name:'原材料仓库'},
      {id:1,imgSrc:require("@/assets/images/mpItem/mp20/3.png"),name:'生产加工车间'},
      {id:2,imgSrc:require("@/assets/images/mpItem/mp20/4.png"),name:'成品冷冻库'},
      {id:3,imgSrc:require("@/assets/images/mpItem/mp20/5.png"),name:'冷藏运输车'},
    ],
    p3:'',
    p3H:'',
    p3Text:[],
    p3Text1:[''],
    p4:'中科物联药品零售企业温湿度监控方案',
    p4Text:[
      {id:0,name:'原材料、成品仓库、生产车间:',imgSrc:require("@/assets/images/mpItem/mp20/6.png"),imgStyle:false,
      styles:{parent:'',cur:''},},
      {id:1,name:'冷藏运输车:',imgSrc:require("@/assets/images/mpItem/mp20/7.jpg"),imgStyle:false,
      styles:{parent:'',cur:''},}
    ]
  }

