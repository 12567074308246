import Main from '../views/monitor-device/index';
import detail from '../views/monitor-device/components/device-detail'
const monitorDevice = [
    {
        path: '/monitorDevice',
        component: Main,
    },
    {
        path: '/deviceDetail/:id',
        component: detail,
    }
];

export default monitorDevice;