/**
 * 基于 vue-resouce 的异步请求封装
 *   以提供 日志打印、错误处理、方法判断、统一请求参数类型、设置Loading状态 等功能
 * 调用示例：
 *   import Api from '../api'
 *   Api.getWxConfig(config).then(res => {}).catch(e => {})
 *
 * 参数说明：
 * config [Object]:
 *  config.data [Object] 请求的数据，统一为 JSON 对象
 *  config.logger [Boolean] 是否打印日志 默认 false
 *  config.catchError [Boolean] 是否捕捉错误并显示错误消息
 *  config.urlData [Array] 请求地址中要拼接的参数对象，拼接顺序为数组顺序 例 xx/user/${id}/${name}
 *  config.stateName [String] 请求过程中需要更新的 loadding 状态名称 （需要 context 配置）
 *  config.context [Object] 当前页面实例 this （当配置了 stateName 时，context 必填）
 *  config.options [Object] vue-resource 的额外配置 如 {emulateJSON: true}
 */
import axios from 'axios';
const defaultUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://yz.z-hope.cn:8080/zhope/service'
    : 'http://yz.z-hope.cn';
const BaseURL = process.env.LVTONG_URL || defaultUrl;


const instance = axios.create({
  baseURL: '/zhope/service' ,
  timeout: 60 * 1000,
  maxRedirects: 3,
});

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    const data = response.data;
    // if (data?.errCode !== 0) {
    //   return Promise.reject(data?.msg);
    // }
    // // 对响应数据做点什么
    // return data?.datas;
    return data;
  },
  function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  },
);

const createAsyncRequest = (apiConfig) => {
  // 将每个业务 api 地址转为方法
  Object.keys(apiConfig).forEach((v) => {
    const urlInfo = apiConfig[v].split(' ');

    const method = urlInfo[0].toLowerCase();
    const isGet = method === 'get';

    // 请求参数处理
    apiConfig[v] = (config = {}) => {
      const {
        context = null,
        options = { emulateJSON: false },
        stateName = '',
        // urlData = [],
        contentType = 'json',
      } = config;
      let data = config.data || {};

      // stateName 与 context 必需同时存在
      if (stateName && !context) throw new Error('[fetch.js warn]: context is requred!');

       //  处理 get请求参数
       if (isGet && Object.keys(data).length) options.params = data;

      // 拼接URL
      let url = urlInfo[1];
      // if (urlData.length) url = `${url}/${urlData.join('/')}`;
      // console.log("options.params----------------------------",options.params);
      if (options.params) {
        for (let key in options.params) {
          url += `/${options.params[key]}`;
        }
      }
      // console.log("url----------------------------",url);
      // 日志 打印
      // logger && console.log('%c============================= S', INFO_COLOR)
      // logger && console.log('%c请求地址: ', INFO_COLOR, url)
      // logger && console.log('%c请求参数: ', INFO_COLOR, data)

     const contentTypeMap = {
       json: 'application/json',
       form: 'application/x-www-form-urlencoded',
     };
      // 返回 Promise对象
      return instance.request({
        url,
        method,
        data,
        // params: isGet ? data : undefined,
        params:"",
        headers: {
          'Content-Type': contentTypeMap[contentType],
        },
      });
    };
  });
  return apiConfig;
};

export default createAsyncRequest;
export { BaseURL };
