<template>
   <ProductDetail :productId="productId" />
</template>

<script>
import ProductDetail from "@/components/product-detail.vue"

export default {
    components:{
        ProductDetail
    },
  data() {
    return {
        productId:0,
    };
  },
  mounted() {
    this.productId = this.$route.params.id;
  },
  methods: {
  },
};
</script>

<style lang="less" scoped>

</style>
