import Main from '../views/terrace-intro';
import terraceIntro2 from '../views/terrace-intro/terraceIntro2';
const terraceIntro = [
  {
    path:'/terraceIntro',
    component: Main,
  },
  {
    path:'/terraceIntro2',
    component: terraceIntro2,
  },
];

export default terraceIntro;