<template>
  <div class="wrapper">
    <div class="banner">
      <img src="@/assets/images/news/news_head.jpg" alt="" />
    </div>
    <div class="news_content">
      <h3>{{ newstData.name }}</h3>
      <div class="date">{{ dateFormat(newstData.createTime) }}</div>
      <div class="con" v-html="newstData.content"></div>
    </div>
    <!-- <div class="prod_wrap" v-html="newstData.content"></div> -->
  </div>
</template>

<script>
import { imgSrc } from "@/api/home";

export default {
  name:'newsDetail',
  data() {
    return {
      newstData: {},
      imgSrc,
    };
  },
  mounted() {
    this.getDetailList();
    // this.format(row.createAt,'yyyy-mm-dd HH:mm:ss');
  },
  methods: {
    getDetailList() {
      let data = {
        id: this.$route.params.id,
      };
      this.$api.getNewsDetail({ data }).then((res) => {
        this.newstData = res.datas;     
      });
    },
    dateFormat(time){
      return time.slice(0,10)
    }
//     dateFormat:function(time) {
//     var date=new Date(time);
//     var year=date.getFullYear();
//     /* 在日期格式中，月份是从0开始的，因此要加0
//      * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
//      * */
//     var month= date.getMonth()+1<10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
//     var day=date.getDate()<10 ? "0"+date.getDate() : date.getDate();
//     var hours=date.getHours()<10 ? "0"+date.getHours() : date.getHours();
//     var minutes=date.getMinutes()<10 ? "0"+date.getMinutes() : date.getMinutes();
//     var seconds=date.getSeconds()<10 ? "0"+date.getSeconds() : date.getSeconds();
//     // 拼接
//     return year+"-"+month+"-"+day+" "+hours+":"+minutes+":"+seconds;
// }
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  .banner {
    img {
      width: 100vw;
      height: calc(width / 6.4);
    }
  }
  .news_content {
    width: 1200px;
    min-height: 600px;
    margin: 0 auto;
    padding: 20px 0 50px;
    h3 {
      color: #333e4c;
      font-size: 36px;
      font-weight: bold;
      padding: 30px 0px 15px;
      text-align: left;
    }
    .date {
      font-size: 15px;
      color: #999;
      padding-left: 10px;
      text-align: left;
    }
    /deep/.con {
      text-align: left;
      padding: 20px 40px;
      margin: 20px 0;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      .prod_title {
        font-size: 24px;
      }
      p {
        display: flex;
        justify-content: center;
        font-size: 16px;
        color: #666;
        line-height: 24px;
        // text-indent: 2em;
        img {
          text-align: center;
        }
      }
      table {
        border: 1px solid #e1e7f0;
      }
      th {
        padding: 20px;
        text-align: left;
        font-size: 18px;
        font-weight: normal;
        border: 1px solid #e1e7f0;
      }
      td {
        padding: 20px;
        text-align: left;
        font-size: 16px;
        border: 1px solid #e1e7f0;
      }
    }
  }
}
</style>