<template>
  <div class="wrapper">
    <div class="banner">
      <img src="@/assets/images/sell/sale_head.jpg" alt="" />
    </div>
    <div class="sale_content">
      <div class="sale_item" v-for="item in sellList" :key="item.id">
        <div class="name">{{ item.icon }}</div>
        <div class="con">
          <h3>
            {{ item.compare }}
            <a v-if="item.tel" :href="item.telHref">{{ item.tel }}</a>
          </h3>
          <p>
            <span>
              <a v-if="item.phone" :href="item.phoneHref">电话：{{ item.phone }}</a>
              <a v-if="item.phone3" :href="item.phoneHref3"
                >、{{ item.phone3 }}</a
              >
            </span>
            <span v-if="item.email" class="mail">邮箱：{{ item.email }}</span>
            <span>{{ item.name }}</span>
          </p>
          <p v-if="item.name2">
            <span
              ><a :href="item.phoneHref2">电话：{{ item.phone2 }}</a></span
            ><span class="mail">邮箱：{{ item.email2 }}</span
            ><span>{{ item.name2 }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sellList } from "@/utils/config/sell";

export default {
  name:'sellIndex',
  data() {
    return {
      sellList,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  a {
    color: #333e4c;
    text-decoration: none;
  }
  .banner {
    img{
      width: 100vw;
      height: calc(width / 6.4);
    }
  }
  .sale_content {
    width: 100%;
    padding: 10px 0 60px 0;
    .sale_item {
      width: 1200px;
      height: 90px;
      padding: 20px;
      margin: 0 auto;
      border-bottom: 2px solid #e1e7f0;
      .name {
        width: 60px;
        height: 60px;
        background-color: #36404d;
        color: #fff;
        font-size: 40px;
        line-height: 56px;
        text-align: center;
        margin: 15px 20px;
        float: left;
      }
      .con {
        display: inline-block;
        /* width: 700px; */
        margin-top: 10px;
        float: left;
        h3 {
          width: 550px;
        display: flex;
        justify-content: space-between;
          margin: 0;
          color: #333e4c;
          font-size: 28px;
          line-height: 40px;
          text-align: left;
        }
        p {
          margin: 3px auto;
          font-size: 16px;
          line-height: 24px;
          color: #666;
          text-align: left;
          span {
            margin-right: 30px;
          }
          .mail {
            width: 280px;
            margin-right: 60px;
            display: inline-block;
          }
        }
      }
    }
  }
}
</style>