<template>
  <div class="wrapper">
    <!-- banner -->
    <div class="banner">
      <img src="@/assets/images/terrace/banner.jpg" alt="" />
    </div>
    <!------  温湿度自动监控平台先驱者 ------>
    <div class="main">
      <div class="heat">
        <div class="heat-title">平台简介</div>
        <p>
          中科物联研发团队具备20余年互联网产品开发经验，丰富的高并发、高负载系统开发经验，安全稳健，数据管理实现高可靠性和服务可用性；中科物联云平台对实时采集和实时上传的数据进行全程可视化展现，支持温湿度超标数据的报警管理，可以无缝对接药企各种数据，可以将不同品牌设备的多种监测系统，在中科云平台上进行统一可视化监测和统一管理；中科云平台也可以为药企提供统一和协同管理，包括将仓库、冰柜、保温箱以及冷藏车的数据统一在云平台上进行监测，针对集团公司，统一支持总仓、分仓以及门店等异地数据的监测和管理，真正实现全冷链的监测及管理。
        </p>
        <div class="heat-title">平台优势</div>
        <div class="heat-main">
          <img
            v-for="item in heatData.list"
            :key="item.id"
            :src="item.offImg"
            @mouseenter="enterDevice(item)"
            @mouseleave="leaveDevice(item)"
            @click="modelFunction(item.id)"
          />
        </div>
        <Model v-if="isShowModel" :modelId="modelId" @showModel="showModel" />
      </div>
      <!-- end -->
      <div class="function">
        <div class="funTitle">平台功能</div>
        <div class="function_main">
          <div>集团管理</div>
          <div>全程监测</div>
          <div>数据总览</div>
          <div>实时显示</div>
          <div>历史追溯</div>
          <div>数据备份</div>
          <div>数据统计</div>
        </div>
        <div class="function_main">
          <div>超标通知</div>
          <div>故障检测</div>
          <div>数据共享</div>
          <div>用户权限</div>
          <div>数据对接</div>
          <div>定制需求</div>
          <div>本地部署</div>
        </div>
      </div>

      <div class="gouJia">
        <div class="gouJiaTitle">系统架构</div>
        <img
          src="@/assets/images/mpItem/mpItem_4.jpg"
          alt=""
        />
      </div>
      <!------  全面解决方案 ------>

      <div class="scheme">
        <div class="scheme-title">应用行业、场景</div>
        <div class="scheme-main">
          <div
            class="scheme-main_item"
            v-for="item in schemeData.list"
            :key="item.id"
            @mouseenter="enterDevice(item)"
            @mouseleave="leaveDevice(item)"
            @click="toPlan(item)"
          >
            <img :src="item.offImg" alt="" />
            <div class="scheme-main_itemName">{{ item.name }}</div>
          </div>
        </div>
      </div>

      <!------  荣誉资质 ------>

      <div class="tab-content">
        <!-- <div class="item">
          <div class="title">{{ zizhi.title }}</div>
          <p>{{ zizhi.title_p1 }}</p>
          <p>{{ zizhi.title_p2 }}</p>
          <p>{{ zizhi.title_p3 }}</p>
          <div v-for="item,index in honorData.honorImgList.slice(0,6)" 
            :key="index" >
            <img
            :src="item.imgSrc" 
            alt="" 
            style="width:100%;height:100%" 
          />
          </div>
        </div>
        <div class="item">
          <div class="title">{{ qiye.title }}</div>
          <p>{{ qiye.title_p1 }}</p>
          <div class="qiyeImg">
            <img :src="qiye.title_imgSrc1" alt="" />
            <img :src="qiye.title_imgSrc2" alt="" />
          </div>
        </div> -->
        <div class="item" ref="tabItem1">
          <div class="title">{{ honorData.title }}</div>
          <!-- <p>{{ honorData.pOne }}</p>
          <p>{{ honorData.pTwo }}</p> -->
          <p v-for="item,i in honorData.pTextList" :key="i">{{item}}</p>
          <img 
            v-for="item,index in honorData.honorImgList" 
            :key="index" 
            :src="item.imgSrc" 
            alt="" 
            style="object-fit: contain; width: 100%;" 
            :class="index===8||index===9?'honorImg':''"
          />
        </div>
        <div class="item">
          <div class="title">{{ kehu.title }}</div>
          <img class="kehuImg" :src="kehu.title_imgSrc" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Model from '@/components/terrace-model.vue'
import { zizhi, qiye, kehu } from '@/utils/config/about'

import { heatData, schemeData, honorData } from '@/utils/config/home'

export default {
  name:'homeIndex',
  components: {
    Model
  },
  data() {
    return {
      heatData,
      schemeData,
      honorData,

      zizhi,
      qiye,
      kehu,

      isShowModel: false,
      modelId: 0
    }
  },
  mounted() {},
  methods: {
    toPlan(item) {
      let routeData = this.$router.resolve({
        path: item.routeUrl
      })

      //必要操作，否则不会打开新页面
      window.open(routeData.href, '_blank')
    },
    enterDevice(item) {
      item.toggle = item.offImg
      item.offImg = item.onImg
    },
    leaveDevice(item) {
      item.offImg = item.toggle
    },
    modelFunction(id) {
      this.isShowModel = true
      this.modelId = id
    },
    showModel(isShowModel) {
      this.isShowModel = isShowModel
    }
  }
}
</script>

<style lang="less" scoped>
.honorImg{
  margin: 0 53px;
  width: 40% !important;
}
.wrapper {
  background-color: #f3f7fa;
  .banner {
    img {
      width: 100vw;
      height: calc(width / 6.4);
    }
  }
  .main {
    width: 1200px;
    margin: 0 auto;
    .heat {
      min-width: 1200px;
      margin: 0 auto;
      background-color: #f3f7fa;
      text-align: left;
      // text-indent: 2em;
      font-size: 16px;
      line-height: 26px;
      .heat-title {
        margin: 30px auto;
        color: #333e4c;
        font-size: 36px;
        font-weight: bold;
        text-align: left;
      }
      p {
        text-indent: 2em;
      }
      .heat-text {
        width: 260px;
        margin: 10px auto 0px;
        display: flex;
        justify-content: space-between;
        font-size: 26px;
      }
      .heat-main {
        width: 1000px;
        margin: 30px auto;
        display: flex;
        justify-content: space-between;
        img {
          object-fit: contain;
          cursor: pointer;
        }
      }
      .heat-footBtn {
        width: 100px;
        padding: 5px 15px;
        margin: 0 auto;
        display: flex;
        border: 1px solid #000;
        text-align: center;
      }
    }

    .function {
      width: 100%;
      .funTitle {
        margin-top: 30px;
        color: #333e4c;
        font-size: 36px;
        font-weight: bold;
        text-align: left;
      }
      .function_main {
        display: flex;
        justify-content: space-around;
        div {
          width: 77px;
          margin-top: 30px;
          padding: 10px;
          font-size: 26px;
          font-weight: bold;
          border: 1px solid #333e4c;
        }
      }
    }

    .gouJia {
      margin-top: 30px;
      text-align: left;
      .gouJiaTitle {
        margin-bottom: 30px;
        color: #333e4c;
        font-size: 36px;
        font-weight: bold;
      }
    }

    .scheme {
      min-width: 1200px;
      margin-top: 30px;
      text-align: left;
      .scheme-title {
        color: #333e4c;
        font-size: 36px;
        font-weight: bold;
      }
      .scheme-text {
        margin-top: 20px;
        font-size: 26px;
        font-weight: 600;
      }
      .scheme-main {
        width: 1200px;
        margin-top: 30px;
        display: flex;
        flex-flow: wrap;
        justify-content: space-around;
        .scheme-main_item {
          font-size: 0;
          margin-bottom: 30px;
          cursor: pointer;
          .scheme-main_itemName {
            color: #fff;
            font-size: 24px;
            font-weight: bold;
            background-color: #333e4c;
            text-align: center;
          }
        }
      }
    }
    .tab-content {
      width: 1200px;
      margin: 0 auto;
      padding-bottom: 60px;
      .item:first-child {
        margin-top: 0px;
      }
      .item {
        margin-top: 30px;
        text-align: left;
        .title {
          font-size: 36px;
          font-weight: bold;
          color: #333e4c;
        }
        p {
          color: #666;
          font-size: 16px;
          line-height: 30px;
          text-indent: 2em;
        }
        .title_h2 {
          margin-top: 30px;
          color: #2eb1c1;
          font-size: 20px;
        }
        ol {
          padding-inline-start: 16px;
          li {
            list-style: disc;
            padding-inline-start: 0px;
            color: #666;
            font-size: 16px;
            line-height: 30px;
          }
        }
        .qiyeImg {
          display: flex;
          justify-content: space-around;
        }
        .kehuImg {
          margin-top: 30px;
          width: 100%;
        }
      }
    }
  }

  .text_cut {
    width: 2px;
    height: 23px;
    margin-top: 7px;
    border-left: 2px solid #333e4c;
  }
}
</style>