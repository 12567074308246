<template>
  <div id="app">
    <HeadNav />
    <router-view />
    <Footer />
  </div>
</template>
<script>
import HeadNav from "./components/headNav.vue";
import Footer from "./components/footer.vue";
export default {
  components: {
    HeadNav,
    Footer,
  },
  data() {
    return {};
  },
  mounted() {
  },
  methods: {
  },
};
</script>
<style lang="less" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding-top: 63px;
}
</style>
