<template>
  <div class="wrapper fixedStyle">
    <div class="head">
      <div class="head-nav">
        <div class="head-nav_logo" @click="backHome()">
          <img src="../assets/images/logo.png" alt="" loading="lazy"/>
        </div>
        <div class="head-nav_list">
          <!--顶部菜单部分-->
          <ul>
            <li
              v-for="(item, index) in menuList"
              :key="item.id"
              @mouseenter="hoverEnter(item, index)"
              @mouseleave="hoverLeave()"
              @click.stop="toDetail(item, index)"
            >
              {{ item.name
              }}<span
                v-if="item.groups.length"
                :class="isNavNum === index ? 'hover-up' : 'hover-down'"
              ></span>
              <!--二级菜单部分-->
              <ul
                class="hidden-boxActive"
                v-show="item.groups.length && isNavNum === index"
              >
                <li
                  v-for="(item2, index2) in item.groups"
                  :key="item2.id"
                  @mouseenter="hoverEnter2(index2)"
                  @mouseleave="hoverLeave2()"
                  @click="emit(item, index, index2)"

                >
                  {{ item2.name
                  }}<b
                    v-if="item2.children"
                    :class="spanIcon === index2 ? 'hover-up3' : 'hover-down3'"
                  ></b>
                  <ul
                    :class="initShow2 ? 'hidden-boxActive scrollNav':'hidden-boxActive'"
                    v-show="threeIndex === index2 && isThree"
                    :style="isShow2 && initShow2 ? 'width: 320px;' :''"
                  >
                  <div style="display: flex;">
                    <div>
                      <!-- 三级 -->
                      <li
                        v-for="item3,index3 in item2.children"
                        :key="item3.id"
                        @mouseenter="hoverEnter3(item3,index3)"
                        @mouseleave="hoverLeave3()"
                        @click="toMpItem(item3.routeUrl)"
                      >
                        {{ item3.name }}
                        <b
                          v-if="item3.children"
                          :class="spanIcon1 === index3 ? 'hover-up3' : 'hover-down3'"
                        ></b>
                        <ul
                          class="hidden-boxActive"
                          v-show="fiveIndex === index3 && isFive"
                        >
                          <li
              
                            v-for="item4 in item3.children"
                            :key="item4.id"
                            @click="toMpItem(item4.routeUrl)"
                          >
                            {{ item4.name }}
                          </li>
                        </ul>
                      </li>
                    </div>
                    <!-- 站位 -->
                      <div v-show="isShow2 && initShow2" style="width: 160px;"></div>
                    </div>
                  </ul>
                </li>
              </ul>
            </li>
            <!-- 顶部导航尾部纯静态部分 -->
            <li
              v-for="(item2, index2) in menuStaticList"
              :key="item2.id"
              @click.stop="toDetail(item2, index2)"
            >
              {{ item2.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isNavNum: 99, //切换
      spanIcon: 99,
      spanIcon1: 99,
      isThree: false, // 三级菜单
      isFive:false, //四级菜单
      threeIndex: 0,
      fiveIndex:0,
      isShow2:false,
      menuList: [],
      threeMenuList: [
        [
          { id: 5, name: "仓库监测", routeUrl: "/mpItem5" },
          { id: 6, name: "冷柜监测", routeUrl: "/mpItem6" },
          { id: 7, name: "保温箱监测", routeUrl: "/mpItem7" },
          { id: 8, name: "冷藏车监测", routeUrl: "/mpItem8" },
        ],
        [
          { 
            id: 1,
            name: "医药行业",
            routeUrl: "/mpItem1",
            children:[
             { id: 3, name: "疾控中心", routeUrl: "/mpItem3" },
              {
                id:'1-1',
                name:'血液中心',
                routeUrl: "/mpItem1-1",
              },
              {
                id:'1-2',
                name:'药品经营企业',
                routeUrl: "/mpItem1-2",
              },
              {
                id:'1-3',
                name:'药品零售企业',
                routeUrl: "/mpItem1-3",
              },
              {
                id:'1-4',
                name:'药品生产企业',
                routeUrl: "/mpItem1-4",
              },
              {
                id:'1-5',
                name:'医院',
                routeUrl: "/mpItem1-5",
              },
              { id: 2, name: "医疗结构", routeUrl: "/mpItem2" },

            ]
          },
          { id: 4, name: "物流冷链", routeUrl: "/mpItem4" },
          { 
            id: 5, 
            name: "食品行业",
            routeUrl: "/mpItem9",
            children:[
              { id: '5-1', name: "海鲜水产", routeUrl: "/mpItem9" },
              { id: '5-2', name: "低温、速冻类食品", routeUrl: "/mpItem10" },
              { id: '5-3', name: "果蔬肉蛋", routeUrl: "/mpItem11" },
              { id: '5-4', name: "预制菜", routeUrl: "/mpItem12" },
              { id: '5-5', name: "冰激凌", routeUrl: "/mpItem20" }
            ],
          },
          {
            id:6,
            name:'仓储物流',
            routeUrl: "/mpItem13",
            // children:[],
          },
          {
            id:7,
            name:'半导体存储',
            routeUrl: "/mpItem14",
            // children:[],
          },
          {
            id:8,
            name:'实验室',
            routeUrl: "/mpItem15",
            // children:[],
          },
          {
            id:9,
            name:'化学危险物品',
            routeUrl: "/mpItem16",
          },
          {
            id:10,
            name:'粮食存储',
            routeUrl: "/mpItem17",
          },
          {
            id:11,
            name:'温室大棚',
            routeUrl: "/mpItem18",
          },
          {
            id:12,
            name:'烟草行业',
            routeUrl: "/mpItem19",
          }
        ],
      ],
      menuStaticList: [
        { id: 101, name: "联系我们", routeUrl: "/sell" },
        // { id: 102, name: "资讯信息", routeUrl: "/news" },
        { id: 103, name: "关于我们", routeUrl: "/about" },
      ],
      // 一级menu路由
      menuRouteList: [
        "", // 平台介绍
        "/monitorDevice", // 监测设备
        "/verifyDevice", // 验证设备
        "", // 监测方案
        "/verifyPlan", // 验证方案
      ],
    };
  },
  computed:{
    initShow2(){
      const h = document.body.clientHeight
      // 730 ,最底部二级导航:下拉框高度600 + 顶部导航63 + 空隙63 取整
      return h<730
    },
    
  },
  mounted() {
    this.getMenusList();
  },
  methods: {
    emit(item, index1, index2) {
      this.$store.commit("UPDATE_Index", index2);

      if (index1 === 0 && index2 === 0) {
        let routeData = this.$router.resolve({
          path: "/terraceIntro",
        });
        window.open(routeData.href, "_blank");
        this.hoverLeave();
      } else if (index1 === 0 && index2 === 1) {
        let routeData = this.$router.resolve({
          path: "/terraceIntro2",
        });
        window.open(routeData.href, "_blank");
        this.hoverLeave();
      } else if (index1 === 1) {
        let routeData = this.$router.resolve({
          path: item.routeUrl,
          query: {
            curIndex: index2,
          },
        });
        window.open(routeData.href, "_blank");
        this.hoverLeave();
      } else if (index1 === 3) {
        this.$router.push({
          path: "",
          // query: {
          //   curIndex: index2,
          // },
        });
      } else {
        let routeData = this.$router.resolve({
          path: item.routeUrl,
        });
        window.open(routeData.href, "_blank");
        this.hoverLeave();
      }
    },
    hoverEnter(item, index) {
      if (item.groups.length !== 0) {
        this.isNavNum = index;
      }
    },
    hoverLeave() {
      this.isNavNum = 99;
    },
    hoverEnter2(index2) {
      this.isThree = true;
      this.spanIcon = this.threeIndex = index2;
    },
    hoverLeave2() {
      this.isThree = false;
      this.spanIcon = 99;
    },
    hoverEnter3(item3,index3) {
      this.isFive = true;
      this.spanIcon1 = this.fiveIndex = index3;

      if (item3.children && item3.children.length>0 ) { 

        this.isShow2 = true
      }
    },
    hoverLeave3() {
      this.isFive = false;
      this.spanIcon1 = 99;
      this.isShow2 = false
    },
    backHome() {
      this.$router.push({
        path: "/",
        query: {},
      });
    },
    async getMenusList() {
      await this.$api
        .getMenus()
        .then((res) => {
          // console.log(res);
          let [...resList] = res?.datas || [];
          this.menuList = resList.map((item, i) => {
            return {
              ...item,
              routeUrl: this.menuRouteList[i],
            };
          });
          
          // 3 验证设备
          const groups3 = this.menuList[3].groups.map((item, i) => {
            return {
              ...item,
              children: this.threeMenuList[i],
            };
          });
          this.menuList[3].groups =  [...groups3]
          console.log("this.menuList", this.menuList);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    stopClose() {
      this.$router.push({
        path: "",
      });
    },
    toMpItem(url) {
      let routeData = this.$router.resolve({
        path: url,
      });
      window.open(routeData.href, "_blank");
    },
    toDetail(item) {
      let routeData = this.$router.resolve({
            path: item.routeUrl,
          });
          window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.scrollNav{
  overflow-y: scroll;
  max-height:calc(100vh - 126px)
}
.fixedStyle {
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;
  background-color: #fff;
}
.bc {
  background: #00bba8;
}
.wrapper {
  .head {
    width: 100%;
    height: 63px;
    font-size: 16px;
    .head-nav {
      width: 1200px;
      height: 63px;
      margin: 0 auto;
      .head-nav_logo {
        width: 200px;
        height: 55px;
        float: left;
        padding: 7px 0 0 40px;
        img {
          cursor: pointer;
        }
      }
      .head-nav_list {
        float: right;
        position: relative;
        z-index: 100;
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          li {
            width: 90px;
            height: 60px;
            display: block;
            float: left;
            color: #333e4c;
            cursor: pointer;
            line-height: 60px;
            font-weight: bold;
            margin: 0 10px;
            span {
              display: inline-block;
              margin-left: 5px;
              width: 7px;
              height: 7px;
              background: url("../assets/images/home/down-icon.png") no-repeat;
              // background-color: #333e4c;
            }
            .hover-down {
              transition-duration: 0.5s;
              transform: rotate(0deg);
              -webkit-transform: rotate(0deg);
            }
            .hover-up {
              transition-duration: 0.5s;
              transform: rotate(180deg);
              -webkit-transform: rotate(180deg);
            }
            .hover-down3 {
              transition-duration: 0.5s;
              transform: rotate(0deg);
              -webkit-transform: rotate(0deg);
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 6px;
              border-color: transparent transparent transparent grey;
              position: absolute;
              right: 10px;
              top: 40%;
            }
            .hover-up3 {
              transition-duration: 0.5s;
              transform: rotate(-90deg);
              -webkit-transform: rotate(90deg);
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 6px;
              border-color: transparent transparent transparent grey;
              position: absolute;
              right: 10px;
              top: 40%;
            }
            .hidden-boxActive {
              display: inline-block;
              width: auto;
              height: auto;
              font-weight: 600;
              background-color: #f3f7fa;
              box-shadow: 0 13px 23px -6px rgb(0 0 0 / 50%);
              -webkit-box-shadow: 0 13px 23px -6px rgb(0 0 0 / 50%);
              animation: slide-down 0.3s ease-in;
              transform-origin: 50% 0;
              @keyframes slide-down {
                0% {
                  transform: scale(1, 0);
                }
                100% {
                  transform: scale(1, 1);
                }
              }
              li {
                position: relative;
                margin: 0;
                width: 160px;
                // span {
                //   width: 0;
                //   height: 0;
                //   border-style: solid;
                //   border-width: 6px;
                //   border-color: transparent transparent transparent grey;
                //   position: absolute;
                //   right: 0;
                //   top: 15px;
                // }
                ul {
                  position: absolute;
                  left: 160px;
                  top: 0px;
                  background-color: #f3f7fa;
                  animation: slide-down 0.3s ease-in;
                  transform-origin: 50% 0;
                  @keyframes slide-down {
                    0% {
                      transform: scale(1, 0);
                    }
                    100% {
                      transform: scale(1, 1);
                    }
                  }
                }
              }
              li:hover {
                color: #f3f7fa;
                background: #00bba8;
                border-bottom: none;
              }
            }
            .hidden-box {
              display: none;
            }
          }
          li:hover {
            border-bottom: 3px solid #00bba8;
          }
        }
      }
    }
  }
}
</style>