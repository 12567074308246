<template>
  <Groups :curIndex.sync="curIndex" :groupsList="groupsList" />
</template>

<script>
import Groups from "@/components/groups.vue";

export default {
  name:'GroupsList',
  components: {
    Groups,
  },
  data() {
    return {
      curIndex: 0,
      groupsList: [],
    };
  },
  mounted() {
    this.curIndex = Number(this.$route.query.curIndex);
    this.getModelList(); // 二级列表数据获取
  },
  watch: {
    "$route.query.curIndex"(val) {
      this.curIndex = Number(val);
    },
    immediate: true,
  },
  methods: {
    async getModelList() {
      let data = {
        id: 2,
      };
      await this.$api.getModel({ data }).then((res) => {
        this.groupsList = res.datas.groups;
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>