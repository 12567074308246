<template>
  <div class="wrapper">
    <!-- :style="`background: url(${imgSrc}${pruductData.maxImg}) no-repeat center;`" -->
    <div class="prod_head">
      <div class="prod"><img :src="imgSrc + pruductData.maxImg" loading="lazy" /></div>
      <div class="prod_name">
        {{ pruductData.name }}<br />{{ pruductData.typeName }}
      </div>
    </div>
    <div class="prod_wrap" v-html="pruductData.content"></div>
  </div>
</template>

<script>
import { imgSrc } from "@/api/home";

export default {
  props: {
    productId: {
      type: Number,
    },
  },
  data() {
    return {
      pruductData: {},
      imgSrc,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.getDetailList();
  },
  watch: {
    productId(val) {
      if (val) {
        this.productId = val;
        this.getDetailList();
      }
    },
    immediate: true,
  },
  methods: {
    getDetailList() {
      let data = {
        id: this.productId,
      };
      this.$api.getProduct({ data }).then((res) => {
        this.pruductData = res.datas;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  .prod_head {
    width: 100%;
    height: 512px;
    background: url("@/assets/images/monitor-device/product_bg.jpg") no-repeat
      center;
    .prod {
      width: 450px;
      height: 310px;
      margin: 0 auto;
      padding-top: 114px;
      img {
        width: 450px;
        height: 310px;
        margin: 0;
        padding: 0;
        border: none 0;
      }
    }
    .prod_name {
      width: 290px;
      height: 63px;
      margin: 0 auto;
      // margin-top: 20px;
      padding: 5px 20px;
      background-color: #3b4551;
      text-align: center;
      font-size: 22px;
      line-height: 28px;
      color: #fff;
    }
  }
  .prod_wrap {
    width: 100%;
    background-color: #f4f4f4;
    /deep/.prod_content {
      width: 1160px;
      padding: 50px 20px 20px;
      margin: 0 auto;
      border-bottom: 1px solid #dedede;
      .prod_title {
        width: 1130px;
        height: 45px;
        line-height: 45px;
        font-size: 36px;
        font-weight: bold;
        color: #36404d;
        background: url("@/assets/images/list_title.jpg") no-repeat;
        padding-left: 30px;
        position: relative;
        text-align: left;
        .prod_con {
          width: 1100px;
          padding: 30px;
          position: relative;
        }
      }
      p {
        text-align: left;
        font-size: 18px;
        line-height: 30px;
        color: #333;
      }
    }
    /deep/.prod_content {
      width: 1160px;
      padding: 50px 20px 20px;
      margin: 0 auto;
      border-bottom: 1px solid #dedede;
      .prod_title {
        width: 1130px;
        height: 45px;
        line-height: 45px;
        font-size: 36px;
        font-weight: bold;
        color: #36404d;
        background: url("@/assets/images/list_title.jpg") no-repeat;
        padding-left: 30px;
        position: relative;
        text-align: left;
      }
      .prod_con {
        position: relative;
        padding: 30px;
        width: 1100px;
        text-align: left;
        img {
          width: 1200px;
          height: 182px;
        }
        table {
          border: 1px solid #e1e7f0;
        }
        th {
          padding: 20px;
          width: 18%;
          text-align: left;
          font-size: 18px;
          font-weight: normal;
          border: 1px solid #e1e7f0;
        }
        td {
          padding: 20px;
          width: 82%;
          text-align: left;
          font-size: 16px;
          border: 1px solid #e1e7f0;
        }
      }
    }
  }
}
</style>