import createAsyncRequest from './utils/axios';
export const imgSrc = "/zhope/service/img/project/solution/"

const apis = {
  getMenus: 'get /home/menus', // 菜单
  getModel: 'get /home/product/model', // 产品列表
  getProduct: 'get /home/product', // 产品详细
  getNews0: 'get /home/news/find/type/0',// 资讯新闻
  getNews1: 'get /home/news/find/type/1', // 验证介绍新闻
  getNewsDetail: 'get /home/news', // 新闻详细
};

export default createAsyncRequest(apis);


