<template>
  <div class="wrapper">
    <div class="banner">
      <div class="banner-text">
        <p class="bannerTitle">中科物联验证服务</p>
        <p class="banner-p">
          以新版GSP规范为依据的标准化、高效率的验证方案，以“纠偏四要素”为方案核心，以云平台为技术依托，为客户提供全程可视化实时数据的在线验证操作，动态验证分析，实现智能纠偏，验证结论可用于实际使用。
        </p>
      </div>
    </div>
    <div class="tabNav">
      <div class="tabNav-wra" ref="tabTitle">
        <ul class="tabNav-tit">
          <li>
            {{ groupsList.name }}
          </li>
        </ul>
      </div>

      <div class="tab-content">
        <div class="list_content">
          <div class="main">
            <div class="title">
              <h3>{{ groupsList.name }}</h3>
              <p>
                {{ groupsList.content }}
              </p>
            </div>
            <div
              class="box"
              v-for="item in groupsList.products"
              :key="item.id"
              @click="toDetail(item.id)"
            >
              <img :src="imgSrc + item.img" loading="lazy"/>
              <h4>{{ item.name }}</h4>
              <p>{{ item.typeName }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { imgSrc } from "@/api/home";

export default {
  name:'verifyDevice',
  data() {
    return {
      groupsList: {},
      imgSrc,
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    getDataList() {
      let data = {
        id: 3,
      };
      this.$api.getModel({ data }).then((res) => {
        this.groupsList = res.datas;
      });
    },
    toDetail(id){
      this.$router.push(`/vPlanDetail/${id}`)
    }
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  // background-color: #f4f4f4;
  .banner {
    // margin-top: 63px;
    height: 260px;
    display: flex;
    align-items: center;
    background: url("@/assets/images/verify-device/banner.jpg") no-repeat;
    background-size: 100%;
    text-align: center;
    .banner-text {
      width: 1200px;
      margin: 0 auto;
      .bannerTitle {
        margin: 0px auto;
        font-size: 46px;
        font-weight: bold;
        color: #d7c2a6;
      }
      .banner-p {
        font-size: 24px;
        color: #87898d;
      }
    }
  }
  .tabNav {
    .tabNav-wra {
      .tabNav-tit {
        margin: 0 auto;
        padding-top: 19px;
        width: 1200px;
        background-color: #fff;
        text-align: center;
        cursor: pointer;
        li {
          display: block;
          float: left;
          margin-left: 11px;
          padding: 0px 16px;
          width: auto;
          height: 44px;
          border: 1px solid #e3e1e2;
          border-bottom: 0;
          background-color: #f4f4f4;
          color: #333e4c;
          font-size: 16px;
          line-height: 44px;
          text-align: center;
          text-decoration: none;
        }
      }
    }
    .tab-content {
      .list_content {
        width: 100%;
        height: auto;
        display: inline-block;
        padding: 30px;
        background-color: #f4f4f4;
        .main {
          width: 1200px;
          margin:0 auto;
          .title {
            padding-left: 15px;
            margin: 20px 10px;
            width: 1145px;
            background: url(" @/assets/images/list_title.jpg") no-repeat;
            line-height: 26px;
            text-align: left;
            h3 {
              margin-bottom: 0px;
              font-size: 24px;
            }
            p {
              margin: 0;
              font-size: 16px;
            }
          }
          .box {
            position: relative;
            float: left;
            margin: 10px;
            padding: 20px 15px;
            width: 185px;
            height: 225px;
            background: #fff;
            border-bottom: 1px solid #e2dee0;
            cursor: pointer;
            h4 {
              font-size: 20px;
              color: #333e4c;
            }
            p {
              font-size: 13px;
              color: #666;
            }
          }
          .box:hover {
            box-shadow: 0 13px 23px -6px rgb(0 0 0 / 50%);
            -webkit-box-shadow: 0 13px 23px -6px rgb(0 0 0 / 50%);
          }
        }
      }
    }
  }
}
</style>