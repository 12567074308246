const navList = {
    state: () => ({
        navCurIndex:0,
    }),
    getters: {},
    mutations: {
        UPDATE_Index(state,payload){
            state.navCurIndex = payload
        },
    },
    actions: {}
}
export default navList;