import item1 from '../views/monitor-plan/components/item1';
import item1_1 from '../views/monitor-plan/components/item1-1';
import item1_2 from '../views/monitor-plan/components/item1-2';
import item1_3 from '../views/monitor-plan/components/item1-3';
import item1_4 from '../views/monitor-plan/components/item1-4';
import item1_5 from '../views/monitor-plan/components/item1-5';
import item2 from '../views/monitor-plan/components/item2';
import item3 from '../views/monitor-plan/components/item3';
import item4 from '../views/monitor-plan/components/item4';
import item5 from '../views/monitor-plan/components/item5';
import item6 from '../views/monitor-plan/components/item6';
import item7 from '../views/monitor-plan/components/item7';
import item8 from '../views/monitor-plan/components/item8';
import item9 from '../views/monitor-plan/components/item9';
import item10 from '../views/monitor-plan/components/item10';
import item11 from '../views/monitor-plan/components/item11';
import item12 from '../views/monitor-plan/components/item12';
import item13 from '../views/monitor-plan/components/item13';
import item14 from '../views/monitor-plan/components/item14';
import item15 from '../views/monitor-plan/components/item15';
import item16 from '../views/monitor-plan/components/item16';
import item17 from '../views/monitor-plan/components/item17';
import item18 from '../views/monitor-plan/components/item18';
import item19 from '../views/monitor-plan/components/item19';
import item20 from '../views/monitor-plan/components/item20'

const monitorPlan = [
  {
    path:'/mpItem1',
    component: item1,
  },
  {
    path:'/mpItem1-1',
    component: item1_1,
  },
  {
    path:'/mpItem1-2',
    component: item1_2,
  },
  {
    path:'/mpItem1-3',
    component: item1_3,
  },
  {
    path:'/mpItem1-4',
    component: item1_4,
  },
  {
    path:'/mpItem1-5',
    component: item1_5,
  },
  {
    path:'/mpItem2',
    component: item2,
  },
  {
    path:'/mpItem3',
    component: item3,
  },
  {
    path:'/mpItem4',
    component: item4,
  },
  {
    path:'/mpItem5',
    component: item5,
  },
  {
    path:'/mpItem6',
    component: item6,
  },
  {
    path:'/mpItem7',
    component: item7,
  },
  {
    path:'/mpItem8',
    component: item8,
  },
  {
    path:'/mpItem9',
    component: item9,
  },
  {
    path:'/mpItem10',
    component: item10,
  },
  {
    path:'/mpItem11',
    component: item11,
  },
  {
    path:'/mpItem12',
    component: item12,
  },
  {
    path:'/mpItem13',
    component: item13,
  },
  {
    path:'/mpItem14',
    component: item14,
  },
  {
    path:'/mpItem15',
    component: item15,
  },
  {
    path:'/mpItem16',
    component: item16,
  },
  {
    path:'/mpItem17',
    component: item17,
  },
  {
    path:'/mpItem18',
    component: item18,
  },
  {
    path:'/mpItem19',
    component: item19,
  },
  {
    path:'/mpItem20',
    component: item20,
  },
];

export default monitorPlan;