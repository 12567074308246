/* eslint-disable no-useless-escape */
//温湿度自动监控平台先驱者
export const heatData = {
  title: "温湿度自动监控平台先驱者",
  text1: "智能",
  text2: "专注",
  text3: "安全",
  list: [
    {
      id: 1,
      offImg: require("@/assets/images/home/tab_1_off.jpg"),
      onImg: require("@/assets/images/home/tab_1_on.jpg"),
      toggle: "",
    },
    {
      id: 2,
      offImg: require("@/assets/images/home/tab_2_off.jpg"),
      onImg: require("@/assets/images/home/tab_2_on.jpg"),
      toggle: "",
    },
    {
      id: 3,
      offImg: require("@/assets/images/home/tab_3_off.jpg"),
      onImg: require("@/assets/images/home/tab_3_on.jpg"),
      toggle: "",
    },
    {
      id: 4,
      offImg: require("@/assets/images/home/tab_4_off.jpg"),
      onImg: require("@/assets/images/home/tab_4_on.jpg"),
      toggle: "",
    }
  ]
}
// 大型医药物流集团的选择
export const selectData = {
  title: "现场实时验证",
  title2: "大型医药物流集团的选择",
  text1: "全实时数据",
  text2: "全实时验证",
  text3: "全异地管理",
  list: [
    {
      id: 1,
      name: "理念先进",
      describe: "实时验证+现场纠偏，验证结论可直接用于SOP。",
      imgSrc: require("@/assets/images/home/yanzheng1.png"),
      offImg: require("@/assets/images/home/yanzheng_more_off.png"),
      onImg: require("@/assets/images/home/yanzheng_more_on.png"),
      toggle: ""
    },
    {
      id: 2,
      name: "系统先进",
      describe: "验证云平台+APP，全面支持集团客户远程异地管理验证。",
      imgSrc: require("@/assets/images/home/yanzheng2.png"),
      offImg: require("@/assets/images/home/yanzheng_more_off.png"),
      onImg: require("@/assets/images/home/yanzheng_more_on.png"),
      toggle: ""
    },
    {
      id: 3,
      name: "设备先进",
      describe: "全实时验证设备，所有验证测点数据都能实时上传。",
      imgSrc: require("@/assets/images/home/yanzheng3.png"),
      offImg: require("@/assets/images/home/yanzheng_more_off.png"),
      onImg: require("@/assets/images/home/yanzheng_more_on.png"),
      toggle: ""
    },
    {
      id: 4,
      name: "自主研发",
      describe: "验证设备、云平台+APP全部自主研发。",
      imgSrc: require("@/assets/images/home/yanzheng4.png"),
      offImg: require("@/assets/images/home/yanzheng_more_off.png"),
      onImg: require("@/assets/images/home/yanzheng_more_on.png"),
      toggle: ""
    }
  ]
}
// 全系自主研发智能监测设备
export const deviceImg = [
  {
    id: 1,
    offImg: require("@/assets/images/home/tab3_1_off.jpg"),
    onImg: require("@/assets/images/home/tab3_1_on.jpg"),
    toggle: "",
  },
  {
    id: 2,
    offImg: require("@/assets/images/home/tab3_2_off.jpg"),
    onImg: require("@/assets/images/home/tab3_2_on.jpg"),
    toggle: "",
  },
  {
    id: 3,
    offImg: require("@/assets/images/home/tab3_3_off.jpg"),
    onImg: require("@/assets/images/home/tab3_3_on.jpg"),
    toggle: "",
  },
  {
    id: 4,
    offImg: require("@/assets/images/home/tab3_4_off.jpg"),
    onImg: require("@/assets/images/home/tab3_4_on.jpg"),
    toggle: "",
  }
]
// 全面解决方案
export const schemeData = {
  title: "全面解决方案",
  text: "一站式解决方案，助力产业升级",
  list: [
    {
      id: 1,
      name: "医药行业",
      offImg: require("@/assets/images/home/tab4_1_off.jpg"),
      onImg: require("@/assets/images/home/tab4_1_on.jpg"),
      toggle: "",
      routeUrl: "/mpItem1",
    },
    {
      id: 2,
      name: "医疗机构",
      offImg: require("@/assets/images/home/tab4_2_off.jpg"),
      onImg: require("@/assets/images/home/tab4_2_on.jpg"),
      toggle: "",
      routeUrl: "/mpItem2",
    },
    {
      id: 3,
      name: "疾控中心",
      offImg: require("@/assets/images/home/tab4_3_off.jpg"),
      onImg: require("@/assets/images/home/tab4_3_on.jpg"),
      toggle: "",
      routeUrl: "/mpItem3",
    },
    {
      id: 4,
      name: "物流冷链",
      offImg: require("@/assets/images/home/tab4_4_off.jpg"),
      onImg: require("@/assets/images/home/tab4_4_on.jpg"),
      toggle: "",
      routeUrl: "/mpItem4",
    },
    {
      id: 5,
      name: "仓库监测",
      offImg: require("@/assets/images/home/tab4_5_off.jpg"),
      onImg: require("@/assets/images/home/tab4_5_on.jpg"),
      toggle: "",
      routeUrl: "/mpItem5",
    },
    {
      id: 6,
      name: "冷柜监测",
      offImg: require("@/assets/images/home/tab4_6_off.jpg"),
      onImg: require("@/assets/images/home/tab4_6_on.jpg"),
      toggle: "",
      routeUrl: "/mpItem6",
    },
    {
      id: 7,
      name: "保温箱监测",
      offImg: require("@/assets/images/home/tab4_7_off.jpg"),
      onImg: require("@/assets/images/home/tab4_7_on.jpg"),
      toggle: "",
      routeUrl: "/mpItem7",
    },
    {
      id: 8,
      name: "冷藏车监测",
      offImg: require("@/assets/images/home/tab4_8_off.jpg"),
      onImg: require("@/assets/images/home/tab4_8_on.jpg"),
      toggle: "",
      routeUrl: "/mpItem8",
    }
  ]
}
// 覆盖全国的服务范围
export const rangeData = {
  title: "覆盖全国的服务范围",
  mapImgSrc: require("@/assets/images/home/map_pic.jpg"),
  mpaIconSrc: require("@/assets/images/home/map_icon.jpg"),
  text: "提供快速、高效、优质的售前和售后服务。",
  name: "八大分公司、覆盖全国范围。",
  list: [
    "北方区域【北京分公司】",
    "北方区域【河北分公司】",
    "北方区域【青岛分公司】",
    "华南区域【广州分公司】",
    "西南区域【成都分公司】",
    "华中区域【西安分公司】",
    "华东区域【上海分公司】",
    "华东区域【苏州分公司】",
  ],
  btn: "查看联系方式",
}
// 荣誉资质
export const honorData = {
  title: "荣誉资质",
  // pOne: "北京中科希望物联网络科技有限公司中国医药商业协会会员企业，验证云平台和专属定制模板，获得“十佳医药供应链技术创新应用案例”荣誉《集团用户整体验证解决方案》、《支持用户专属模板的验证解决方案》。",
  // pTwo: "北京中科希望物联网络科技有限公司集合各领域专家  (互联网云技术专家、物联网技术专家、数据安全专家、验证技术专家等 )，遵照新版GSP管理要求，自主研发和运营药品冷链全程温湿度可追溯云平台和监测系统，自主研发和生产智能化内嵌式监测设备和验证设备，拥有数十项自主知识产权。",
  pTextList:[
    '1.国家高新技术企业证书（享受国家高新技术企业嵌入式软件产品增值税即征即退税收优惠政策)',
    '2.中关村高新技术企业',
    '3.北京市“专精特新”中小企业',
    '4.北京市“创新型”中小企业',
    '5.发明专利证书2个',
    '6.实用新型发明专利3个',
    '7.计算机软件著作权登记证书28个',
    '8.实验室认可证书(C N A S)',
    '9.信息安全管理体系认证证书(I S O 2 7 0 0 1 )',
    '10.信息技术服务管理体系认证证 书(I S O 2 0 0 0 1 )',
    '11.质量管理三体系认证证(I S O 9 0 0 1 、 1 4 0 0 1 、 4 5 0 0 1 )',
    '12.北京中科希望物联网络科技有限公司中国医药商业协会会员企业，验证云平台和专属定制模板，获得“十佳医药供应链技术创新应用案例”荣誉《集团用户整体验证解决方案》、《支持用户专属模板的验证解决方案》。'
  ],
  // imgSrc: require("@/assets/images/home/rongyu.jpg"),
  honorImgList: [
    { imgSrc: require("@/assets/images/home/rongyu_1.jpg") },
    { imgSrc: require("@/assets/images/home/rongyu_2.jpg") },
    { imgSrc: require("@/assets/images/home/rongyu_3.jpg") },
    { imgSrc: require("@/assets/images/home/rongyu_4.jpg") },
    { imgSrc: require("@/assets/images/home/rongyu_5.jpg") },
    { imgSrc: require("@/assets/images/home/rongyu_6.jpg") },
    { imgSrc: require("@/assets/images/home/rongyu_7.jpg") },
    { imgSrc: require("@/assets/images/home/rongyu_8.jpg") },
    { imgSrc: require("@/assets/images/home/rongyu_9A.jpg") },
    { imgSrc: require("@/assets/images/home/rongyu_9B.jpg") },
  ]
}