import Main from '../views/verify-plan';
import detail from '../views/verify-plan/components/vplan-detail';

const verifyPlan = [
  {
    path:'/verifyPlan',
    component: Main,
  },
  {
    path:'/vPlanDetail/:id',
    component: detail,
  },
];

export default verifyPlan;